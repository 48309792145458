import React from 'react';
import { Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import {
  TEXT_CONTENT_IDS,
  TITLE_IDS,
} from '../../utils/element-ids';

const GridTCContainer = styled(Grid)({
  justifyContent: 'center',
  alignItems: 'flex-start',
  padding: '0 24px 24px',
});

const GridItem = styled(Grid)({
  width: '100%',
});

const MainTitle = styled(Typography)({
  fontSize: '32px',
  color: '#002677',
  fontWeight: 700,
  lineHeight: '40px',
  padding: '24px 0',
});

const DisclaimerContent = styled(Typography)({
  padding: '24px 0 0 16px',
});

const DisclaimerSubtitle = styled(Typography)({
  fontWeight: 700,
  marginTop: '24px',
});

const PaymentTypesContent = styled(Typography)({
  padding: '16px 0 0 16px',
});

const ServicesSubtitle = styled(Typography)({
  marginTop: '16px',
  fontWeight: 700,
});

const PaymentAuthSubtitle = styled(Typography)({
  fontWeight: 700,
  marginTop: '16px',
});

const GridPaymentTypesContainer = styled(Grid)({
  paddingLeft: '32px',
});

const CardsContent = styled(Grid)({
  paddingLeft: '16px',
});

const PaymentTermsAndConditions = () => {
  return (
    <GridTCContainer
      container
      direction="column"
    >
      <GridItem item>
        <MainTitle
          variant="h1"
          data-testid={
            TITLE_IDS.PAYMENT_TERMS_AND_CONDITIONS_TITLE
          }
        >
          Payment Terms and Conditions
        </MainTitle>
        <Typography
          data-testid={
            TEXT_CONTENT_IDS.PAYMENT_TERMS_AND_CONDITIONS_CONTENT
          }
        >
          By providing Optum, Inc. and/or any of its parents,
          subsidiaries or affiliates a card, bank account, or
          other payment method (“Payment”), or by processing a
          payment with Optum, Inc. and/or any of its parents,
          subsidiaries or affiliates (the “Services”) the
          individual using the Services (“You” or “Your”) accepts
          these terms and conditions (the “Terms and
          Conditions”). Services are centralized and offered by
          Optum, Inc., Inc and its affiliates (“Optum” also
          “‘We,” “Us,” and “Our”). Use of the Services without
          agreeing to these Terms and Conditions is not
          permitted.{' '}
          <strong>
            These terms contain a binding arbitration clause and
            by agreeing to these terms You are agreeing to
            resolve any disputes through arbitration.
          </strong>{' '}
          Use of the Services is also subject to the applicable
          Online Services Privacy Policy and Terms of Use
          associated with the website, application, portal, or
          other means of accessing the Services. In the event of
          any conflict between the applicable Online Services
          Privacy Policy and/or Terms of Use and these Terms and
          Conditions, the Terms and Conditions shall govern.
        </Typography>
      </GridItem>
      <Grid
        container
        direction="column"
      >
        <Grid item>
          <DisclaimerSubtitle
            data-testid={
              TEXT_CONTENT_IDS.PAYMENT_TERMS_AND_CONDITIONS_SUBHEADING
            }
          >
            1. Agreement and Amendment
          </DisclaimerSubtitle>
          <PaymentTypesContent>
            Your affirmative act of using the Services
            constitutes Your electronic signature to these Terms
            and Conditions and Your consent to enter into this
            agreement electronically. You may print and retain a
            copy of these Terms and Conditions. To print, You
            will need (i) a device (such as a computer or mobile
            phone) with a web browser and Internet access and
            (ii) either a printer or storage space on such
            device. We may change these Terms and Conditions at
            any time, and such changes will be posted on the
            Services, with the date of the last revision listed
            as the “Effective Date” at the bottom of these Terms.
            Any modifications will be effective immediately upon
            such posting. By continuing to use the Services, You
            consent to any changes to Our Terms. For recurring
            payments, the Terms and Conditions in place at the
            time of any induvial recurring Payment instance will
            govern.
          </PaymentTypesContent>
        </Grid>
        <Grid item>
          <DisclaimerSubtitle>2. Termination</DisclaimerSubtitle>
          <PaymentTypesContent>
            We may terminate Your right to use the Services at
            Our convenience, for any reason or no reason at any
            time and without prior notice. Upon the termination,
            You agree to immediately cease all use of the
            Services. We will not be liable for compensation,
            indemnity, or damages of any sort as a result of
            terminating Your use of the Service, and such
            termination will be without prejudice to any other
            right or remedy We may have, now or in the future.
          </PaymentTypesContent>
          <PaymentTypesContent>
            You may terminate your agreement to these Terms and
            Conditions by ceasing use of the Services at any
            time; however, these Terms and Conditions shall
            remain applicable to any storage of payment methods
            or payment processing that continues.
          </PaymentTypesContent>
        </Grid>
        <Grid item>
          <DisclaimerSubtitle>3. Our Role</DisclaimerSubtitle>
          <PaymentTypesContent>
            Optum, Inc., Inc., a wholly owned subsidiary of
            United HealthGroup Incorporated, provides the
            Services on behalf of its parents, affiliates,
            partners, joint ventures, and both wholly owned
            subsidiaries and entities in which its parents or its
            subsidiaries have a partial ownership interest
            (collectively, “Merchants”). Generally, the Merchant
            associated with a transaction will be the entity who
            owns and/or controls the application or webpage
            through which you are accessing these Services.
            Unless you are processing a payment through a webpage
            or portal owned or controlled by Optum, Inc., Inc. or
            its subsidiaries, Optum, Inc., Inc. is merely
            facilitating the storage and processing of Your
            payment information through the use of one or more
            vendors. Optum is not an agent or representative of
            any Merchant in connection with any sale or purchase
            by You of goods or services from any Merchant.
          </PaymentTypesContent>
          <DisclaimerContent>
            Optum, Inc. may enable You to purchase products or
            services from Merchants using the Payment modality
            You previously saved and or retained as part of the
            Services. Our Services permit You to add, change,
            update, or otherwise delete Payments stored in Your
            digital wallet managed by Us. Purchases are subject
            to the terms, policies, agreements, and conditions of
            the Merchant as well as the Terms and Conditions
            outlined within this document. We are not responsible
            for any disputes between You and Your Merchant and
            have no responsibility or liability to you in the
            performance of or guarantees provided by Your
            Merchant.
          </DisclaimerContent>
        </Grid>
        <Grid item>
          <DisclaimerSubtitle>4. Services</DisclaimerSubtitle>
          <CardsContent
            container
            direction="column"
          >
            <Grid item>
              <ServicesSubtitle>
                a. Payment Services
              </ServicesSubtitle>
              <PaymentTypesContent>
                Merchants may choose to offer Users the ability
                to make one-time or recurring payments using the
                payment methods outlined below. Such payment
                methods are stored in and accessible through the
                Services.
              </PaymentTypesContent>
            </Grid>
            <Grid item>
              <ServicesSubtitle>b. Wallet</ServicesSubtitle>
              <PaymentTypesContent>
                Any Payment Type outlined below will be stored
                using the Services and will be available for You
                to use at Merchants who accept that particular
                payment method.
              </PaymentTypesContent>
              <GridPaymentTypesContainer item>
                <ServicesSubtitle>
                  i. Payment Types
                </ServicesSubtitle>
                <CardsContent item>
                  <ServicesSubtitle>1. Cards</ServicesSubtitle>
                  <PaymentTypesContent>
                    You may use registered Cards to pay for goods
                    or services at Merchants accepting that
                    payment type. You attest and agree that You
                    are an authorized user of the credit, debit,
                    or payment card You store as a Payment or use
                    to complete a payment to a merchant.
                  </PaymentTypesContent>
                </CardsContent>
                <CardsContent item>
                  <ServicesSubtitle>
                    2. Google Pay
                  </ServicesSubtitle>
                  <PaymentTypesContent>
                    You may use Google Pay to pay for goods or
                    services at Merchants accepting that payment
                    type. You attest and agree that You are an
                    authorized user of Google Pay and are using
                    the product in compliance with Google Pay’s
                    terms of use.
                  </PaymentTypesContent>
                </CardsContent>
                <CardsContent item>
                  <ServicesSubtitle>
                    3. ACH/Checking Account
                  </ServicesSubtitle>
                  <PaymentTypesContent>
                    You may make a payment electronically by
                    transferring money from Your U.S. Bank
                    Account at Merchants accepting that payment
                    type. Funds transfers from Your Bank Account
                    or that of a third party usually take at
                    least three to four business days to
                    complete. You may be charged a return fee for
                    any ACH debit transfer request that is
                    rejected by the holding financial institution
                    for any reason (a &quot;Return Fee&quot;).
                    Return Fees are listed in the Fee Schedule
                    and may vary based on the jurisdiction in
                    which You are located. Your location is
                    determined by the primary address that is
                    associated with your Payment Account. You
                    authorize us to debit your Payment Account or
                    to make an electronic fund transfer (or an
                    equivalent bank draft) from your Bank Account
                    to collect such return fee.
                  </PaymentTypesContent>
                  <PaymentTypesContent>
                    If a Merchant offers ACH payment, you must
                    enroll in and complete an ACH Authorization
                    from within the Merchant experience prior to
                    authorizing any transaction. You may revoke
                    your ACH authorization at any time by
                    deleting the ACH payment method from your
                    wallet.
                  </PaymentTypesContent>
                </CardsContent>
              </GridPaymentTypesContainer>
              <GridPaymentTypesContainer item>
                <ServicesSubtitle>
                  ii. Adding or Deleting a Payment Method
                </ServicesSubtitle>
                <PaymentTypesContent>
                  1. Adding a payment method to the Wallet allows
                  for usage throughout Our enterprise (including
                  parents, subsidiaries and affiliates).
                </PaymentTypesContent>
                <PaymentTypesContent>
                  2. Deleting a payment method from your Wallet
                  will remove it across Our enterprise (including
                  parents, subsidiaries and affiliates).
                </PaymentTypesContent>
                <ServicesSubtitle>
                  iii. Availability of Payment Types
                </ServicesSubtitle>
                <PaymentTypesContent>
                  1. Acceptable payment methods can vary at the
                  merchant level based on specific business
                  rules.
                </PaymentTypesContent>
                <ServicesSubtitle>
                  iv. Default Payment Type
                </ServicesSubtitle>
                <PaymentTypesContent>
                  1. You may designate one payment method to be
                  your default payment method. By designating a
                  payment method as your default payment method,
                  you authorize any merchant which accepts that
                  payment method to automatically process your
                  charges or orders using the default payment
                  method.
                </PaymentTypesContent>
              </GridPaymentTypesContainer>
            </Grid>
            <Grid item>
              <PaymentAuthSubtitle>
                c. Payment Authorization
              </PaymentAuthSubtitle>
              <PaymentTypesContent>
                The Services permit you to authorize payments to
                Merchants directly or to third-party services
                that enable transactions with Merchant. The
                payment will not be completed until the Merchant
                processes your payment authorization with Optum,
                Inc.. A Merchant may delay the processing of your
                authorization. The payment authorization will be
                held with us until the transaction is completed.
                You assume responsibility for all charges made
                through the Services.
              </PaymentTypesContent>
              <PaymentTypesContent>
                The Services may also permit you to preauthorize
                Merchants to charge You using recurring payments
                or multiple payments. You may remove any standing
                payment authorization at any time by logging into
                the portal through which you are accessing the
                Services to make the standing payment.
              </PaymentTypesContent>
            </Grid>
          </CardsContent>
        </Grid>
        <Grid item>
          <DisclaimerSubtitle>
            5. Eligibility and Account Creation
          </DisclaimerSubtitle>
          <PaymentTypesContent>
            Payment may be completed using stored payment
            information or as a one-time payment depending on the
            merchant whom you are paying. Eligibility to make a
            payment may be dependent on your creation and
            maintenance of an Optum HealthSafe ID and
            registration on a particular merchant’s website,
            portal, or other means of accessing the Services.
          </PaymentTypesContent>
        </Grid>
        <Grid item>
          <DisclaimerSubtitle>
            6. Account Monitoring
          </DisclaimerSubtitle>
          <PaymentTypesContent>
            You are solely responsible for monitoring your
            connected financial account activity to ensure that
            all any payment processed through the Services are
            authorized by You.
          </PaymentTypesContent>
        </Grid>
        <Grid item>
          <DisclaimerSubtitle>7. Vendors</DisclaimerSubtitle>
          <PaymentTypesContent>
            The Services may be performed in whole or in part by
            one or more third-party service providers. Optum has
            sole discretion over which vendors are used in
            provisioning the Services. You authorize Us to share
            any and all information associated with your use of
            the Services with Our third-party service providers.
            You shall not have the right to remove such
            authorization for future transactions by ceasing use
            of the services.
          </PaymentTypesContent>
        </Grid>
        <Grid item>
          <DisclaimerSubtitle>
            8. Privacy and Confidentiality
          </DisclaimerSubtitle>
          <PaymentTypesContent>
            Information shared with Optum, Inc. related to the
            Services may include personally identifiable
            information sufficient to allow Merchants to process
            transactions authorized by You. You authorize Optum,
            Inc. to share Your personal information with our
            affiliates, parents, partners, subsidiaries,
            Merchants and any other service providers for
            purposes of processing transactions. You authorize
            Optum, Inc. to direct movement of Your personal
            information and data to an alternate service provider
            for any reason and at any time.
          </PaymentTypesContent>
          <PaymentTypesContent>
            Please see the applicable Online Services Privacy
            Policy for more information.
          </PaymentTypesContent>
        </Grid>
        <Grid item>
          <DisclaimerSubtitle>
            9. Representations and Warranties
          </DisclaimerSubtitle>
          <PaymentTypesContent>
            You represent and warrant to Us and participating
            Merchants that: You are eligible to register for
            Convenient Checkout and to use the Services; You will
            use the Services only to initiate transactions on
            behalf of yourself and not on behalf of any other
            person except as expressly permitted by the Merchant;
            You and all transactions initiated by You will comply
            with all local, state and federal laws, rules and
            regulations applicable to You; and You will not use
            the Services, for any fraudulent purpose or for any
            purpose inconsistent with these Terms and Conditions
            or with the Merchant’s terms, policies, rules and
            conditions.
          </PaymentTypesContent>
        </Grid>
        <Grid item>
          <DisclaimerSubtitle>
            10. Limitation of Liability
          </DisclaimerSubtitle>
          <PaymentTypesContent>
            You agree that We have no liability for any loss
            arising out of or relating to: these Services; any
            third-party site or program accessed through the
            Online Services; Any acts or omissions by Us or any
            third party; and/or Your access or use of the
            Services. This limitation of liability includes any
            claim based on warranty, contract, tort, strict
            liability, or any other legal theory.
          </PaymentTypesContent>
          <PaymentTypesContent>
            This limitation of liability does not apply if You
            are a New Jersey resident. With respect to residents
            of New Jersey, any released parties are not liable
            for any damages unless such damages are the result of
            our negligent or reckless acts or omissions; and any
            released parties are not, in any case, liable for
            indirect, incidental, special, consequential, or
            punitive damages.
          </PaymentTypesContent>
          <PaymentTypesContent>
            Other states may also limit liability. If Your state
            does not allow this type of limitation, one or more
            of the limitations above may not apply to You.
          </PaymentTypesContent>
        </Grid>
        <Grid item>
          <DisclaimerSubtitle>
            11. Indemnification
          </DisclaimerSubtitle>
          <PaymentTypesContent>
            You agree to defend, indemnify, and hold harmless any
            released parties from any claim by third parties,
            including reasonable attorneys&apos; fees for counsel
            of Our own choosing, arising out of or related to
            Your breach of these Terms and Conditions or any
            copyright infringement, misappropriation, misuse,
            gross negligence, intentional misconduct, or
            violation of applicable law relating to Your use of
            the Services. You may not transfer or assign any
            rights or obligations under this Agreement. In any
            litigation, You will cooperate with Us in asserting
            any available defenses.
          </PaymentTypesContent>
        </Grid>

        <Grid item>
          <DisclaimerSubtitle>
            12. General Provisions
          </DisclaimerSubtitle>
          <CardsContent
            container
            direction="column"
          >
            <Grid item>
              <DisclaimerSubtitle>
                a. Governing Law and Statute of Limitations
              </DisclaimerSubtitle>
              <PaymentTypesContent>
                You agree that Minnesota law governs these Terms
                and Conditions and any claim or dispute that may
                arise from use of these Services, and You agree
                to the jurisdiction and venue of the state and
                federal courts in Minnesota for any dispute
                involving Optum, Inc. or its employees, officers,
                directors, agents, and providers.
              </PaymentTypesContent>
              <PaymentTypesContent>
                Unless You are a resident of New Jersey, You must
                initiate any cause of action within one year
                after the claim has arisen, or You will be barred
                from pursuing any cause of action. The United
                Nations Convention on Contracts for the
                International Sale of Goods do not apply to these
                Terms.
              </PaymentTypesContent>
            </Grid>
            <Grid item>
              <DisclaimerSubtitle>
                b. Arbitration
              </DisclaimerSubtitle>
              <PaymentTypesContent>
                As permitted by applicable law, any claim or
                controversy that is not resolved informally by
                the parties shall, at the request of a party, be
                resolved by binding arbitration in accordance
                with the applicable commercial arbitration rules
                of the American Arbitration Association. In no
                event may arbitration be initiated more than one
                year following the date the dispute arose. Any
                arbitration proceeding under these Terms and
                Conditions shall be conducted in the state of
                Minnesota. The arbitrator may construe or
                interpret but shall not vary or ignore the terms
                of these Terms and Conditions, shall have no
                authority to award extra contractual damages of
                any kind, including punitive or exemplary
                damages, and shall be bound by controlling law.
                Judgment upon an arbitration award may be entered
                in any court of competent jurisdiction. The
                parties acknowledge that because the Terms and
                Conditions affect interstate commerce, the
                Federal Arbitration Act applies.
              </PaymentTypesContent>
              <PaymentTypesContent>
                No dispute resolution proceeding between You and
                Optum, Inc. shall be permitted to be conducted in
                a class, representative, or private attorney
                general action, nor may any such proceeding be
                consolidated with any other dispute. Optum and
                You agree that any claims related to the validity
                of this class waiver shall only be resolved by a
                court of competent jurisdiction located in the
                state of Minnesota.
              </PaymentTypesContent>
            </Grid>
            <Grid item>
              <ServicesSubtitle>
                c. Survival and Assignment
              </ServicesSubtitle>
              <PaymentTypesContent>
                Your obligations under the following sections
                survive termination of this Agreement: Agreement
                and Amendment; Eligibility; Account Monitoring;
                Vendors; Privacy and Confidentiality;
                Representations and Warranties; Indemnification;
                Limitation of Liability and General Provisions.
                You may not transfer or assign any rights or
                obligations under this Agreement. Optum, Inc. may
                transfer or assign its rights and obligations
                under this Agreement at any time and without
                prior notice.
              </PaymentTypesContent>
            </Grid>
            <Grid item>
              <ServicesSubtitle>d. Waiver</ServicesSubtitle>
              <PaymentTypesContent>
                No waiver by Us will have effect unless such
                waiver is set forth in writing and signed by Us;
                nor will any such waiver of any breach or default
                constitute a waiver of any subsequent breach or
                default.
              </PaymentTypesContent>
            </Grid>
            <Grid item>
              <ServicesSubtitle>
                e. Severability
              </ServicesSubtitle>
              <PaymentTypesContent>
                If any provision of these Terms and Conditions is
                held to be unenforceable or invalid by an
                arbitrator or court of competent jurisdiction,
                the remaining portions of the Terms and
                Conditions will be determined without the
                unenforceable or invalid provision. All other
                provisions will remain in full force and effect.
              </PaymentTypesContent>
            </Grid>
            <Grid item>
              <ServicesSubtitle>
                f. Governing Language
              </ServicesSubtitle>
              <PaymentTypesContent>
                For all purposes, the English language version of
                these Terms and Conditions shall be the original
                governing Instrument. In the event of any
                conflict between the English language version of
                the Terms and Conditions and any subsequent
                translation into any other language, the English
                language version shall govern and control.
              </PaymentTypesContent>
            </Grid>
            <Grid item>
              <ServicesSubtitle>
                g. Entire Agreement
              </ServicesSubtitle>
              <PaymentTypesContent>
                These Terms and Conditions, along with any
                applicable policies, Terms of Use, and Online
                Services Privacy Policy shall constitute the
                entire agreement between You and Us in connection
                with the Services and supersede all previous
                written or oral agreements.
              </PaymentTypesContent>
            </Grid>
          </CardsContent>
        </Grid>
      </Grid>
    </GridTCContainer>
  );
};

export default PaymentTermsAndConditions;
