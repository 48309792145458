export const TITLE_IDS = {
  MAIN_PAGE_TITLE: 'mainPageTitle',
  HELP_MODAL_TITLE_ID: 'helpModalTitle',
  PAYMENT_METHOD_CHANNEL_SELECTOR_TITLE:
    'paymentMethodChannelSelectorTitle',
  PAYMENT_METHOD_CHANNEL_SELECTOR_SUBTITLE:
    'paymentMethodChannelSelectorSubtitle',
  PAYMENT_METHOD_TYPE_SELECTOR_TITLE: 'paymentMethodTypeTitle',
  PAYMENT_METHOD_TYPE_SELECTOR_SUBTITLE:
    'paymentMethodTypeSubtitle',
  PAY_AND_STORE_TYPE_SELECTOR_TITLE: 'payAndStoreTypeTitle',
  PAY_AND_STORE_TYPE_SELECTOR_SUBTITLE:
    'payAndStoreTypeSubtitle',
  SEND_EMAIL_FORM_TITLE: 'sendEmailFormTitle',
  SEND_EMAIL_FORM_SUBTITLE: 'sendEmailFormSubtitle',
  SEND_TEXT_FORM_TITLE: 'sendTextFormTitle',
  SEND_TEXT_FORM_SUBTITLE: 'sendTextFormSubtitle',
  EDIT_PAYMENT_METHOD_FORM_TITLE: 'editPaymentMethodFormTitle',
  REMOVE_PAYMENT_METHOD_TITLE: 'removePaymentMethodTitle',
  PAYMENT_METHOD_LABEL: 'paymentMethodLabelDescription',
  PAYMENT_METHOD_LAST4: 'paymentMethodLabelLast4',
  PAY_AND_STORE_FORM_TITLE: 'payAndStoreFormTitle',
  PAYMENT_METHOD_SELECT_TITLE: 'paymentMethodSelectTitle',
  LOADER_TITLE: 'loaderTitle',
  SESSION_OUTCOME_SUCCESS_TITLE: 'sessionOutcomeSuccessTitle',
  NOTIFICATIONS_METHODS_CONTAINER_NOTIFICATION_SUCCESS_TITLE:
    'notificationsMethodsCOntainerNotificationSuccessTitle',
  WARNING_CARD_TITLE: 'warningCardTitle',
  PAYMENT_TERMS_AND_CONDITIONS_TITLE:
    'paymentTermsAndConditionsTitle',
  WALLET_MANAGEMENT_TITLE: 'walletManagementTitle',
  WALLET_MANAGEMENT_SUBTITLE: 'walletManagementSubtitle',
  PAYMENT_METHOD_VENDOR_ICON: 'paymentMethodLabelVendorIcon',
};

export const TEXT_CONTENT_IDS = {
  AWAITING_PAYMENT_METHOD_DESTINATION:
    'awaitingPaymentMethodDestination',
  AWAITING_PAYMENT_METHOD_STATUS: 'awaitingPaymentMethodStatus',
  AWAITING_PAYMENT_METHOD_EXPIRATION_COUNTDOWN:
    'awaitingPaymentMethodExpirationCountdown',
  AWAITING_PAYMENT_METHOD_CANCEL_BUTTON:
    'awaitingPaymentMethodCancel',
  MANUFACTURER_CARD_LABEL: 'manufacturerCardLabel',
  MANUFACTURER_CARD_LABEL_NON_AGENT:
    'nonAgentManufacturerCardLabel',
  MANUFACTURER_CARD_WALLET_LABEL: 'manufacturerCardWalletLabel',
  INVALID_BANK_ACCOUNT_WALLET_LABEL:
    'walletInvalidPaymentMethodMessage',
  MANUFACTURER_CARD_OPTION_YES: 'manufacturerCardOptionYes',
  MANUFACTURER_CARD_OPTION_NO: 'manufacturerCardOptionNo',
  MANUFACTURER_CARD_OPTION_RADIO_BTN_YES:
    'manufacturerCardOptRadioBtnYes',
  MANUFACTURER_CARD_OPTION_RADIO_BTN_NO:
    'manufacturerCardOptRadioBtnNo',
  CHILD_SESSION_NO_LONGER_VALID_RESEND: 'resendButton',
  CHILD_SESSION_NO_LONGER_VALID_TRY_ANOTHER: 'tryAnotherMethod',
  CHILD_SESSION_NO_LONGER_VALID_CANCEL: 'cancelParentSession',
  CANCEL_PAYMENT_METHOD_TYPE: 'cancelPaymentMethodType',
  SEND_EMAIL_FORM_SUBMIT: 'email2addSubmit',
  SEND_EMAIL_FORM_NOTE: 'email2addNote',
  SEND_EMAIL_FORM_CANCEL: 'sendEmailFormCancel',
  SEND_TEXT_FORM_NOTE: 'text2addNote',
  SEND_TEXT_FORM_SUBMIT: 'text2addSubmit',
  SEND_TEXT_FORM_CANCEL: 'sendTextFormCancel',
  NOTIFICATION_ALERT_TITLE: 'notificationAlertTitle',
  NOTIFICATION_ALERT_MESSAGE: 'notificationAlertMessage',
  NOTIFICATION_ALERT_CLOSE_ICON_BUTTON:
    'notificationAlertCloseIconButton',
  NOTIFICATION_CONTAINER: 'NotificationContainer',
  WARNING_CARD_DESCRIPTION: 'warningCardDescription',
  PAYMENT_METHOD_SETTINGS_LABEL: 'paymentMethodSettings',
  ACH_PAYMENT_METHOD_SETTINGS_LABEL: 'achPaymentMethodSettings',
  ACH_AUTHORIZATION_AGREEMENT_LABEL: 'achAuthorizationAgreement',
  MERCHANT_AUTHORIZATION_CONSENT_TEXT:
    'merchantAuthorizationConsentText',
  PAYMENT_METHOD_CHANNEL_TITLE: 'paymentMethodChannelTitle',
  PAYMENT_METHOD_CHANNEL_NOT_AVAILABLE:
    'paymentMethodChannelNotAvailable',
  SESSION_OUTCOME_SUCCESS_DESCRIPTION:
    'sessionOutcomeSuccessDescription',
  ADD_PAYMENT_METHOD_SAVE_BUTTON: 'addPaymentMethodSaveButton',
  ADD_PAYMENT_METHOD_CANCEL_BUTTON:
    'addPaymentMethodCancelButton',
  ADD_PAYMENT_METHOD_BUTTON_ON_WALLET:
    'walletAddPaymentMethodButton',
  ADD_PAYMENT_METHOD_BACK_BUTTON: 'addPaymentMethodBackButton',
  PAY_AND_STORE_ACH_BACK_BUTTON: 'payAndStoreAchBackButton',
  PAY_AND_STORE_CARD_BACK_BUTTON: 'payAndStoreCardBackButton',
  EDIT_PAYMENT_METHOD_BACK_BUTTON: 'editPaymentMethodBackButton',
  ADD_PAYMENT_ALL_FIELDS_REQUIRED_TEXT:
    'addPaymentAllFieldsRequired',
  ACH_ADD_PAYMENT_ALL_FIELDS_REQUIRED_TEXT:
    'achAddPaymentAllFieldsRequired',
  EDIT_PAYMENT_ALL_FIELDS_REQUIRED_TEXT:
    'editPaymentAllFieldsRequired',
  SEND_EMAIL_FORM_BACK_BUTTON: 'sendEmailFormBackButton',
  SEND_TEXT_FORM_BACK_BUTTON: 'sendTextFormBackButton',
  PAYMENT_METHOD_TYPE_SELECTOR_BACK_BUTTON:
    'paymentMethodTypeBackButton',
  ADD_PAYMENT_SET_AS_DEFAULT_CHECKBOX:
    'addPaymentSetAsDefaultCheckbox',
  ADD_PAYMENT_SET_AS_DEFAULT_CHECKBOX_ACH:
    'achAddPaymentSetAsDefaultCheckbox',
  ACH_AUTHORIZATION_CHECKBOX: 'achAuthorizationCheckbox',
  EDIT_PAYMENT_SET_AS_DEFAULT_CHECKBOX:
    'editPaymentSetAsDefaultCheckbox',
  WALLET_SUBMIT_PAYMENT_BUTTON: 'walletSubmitPaymentButton',
  WALLET_CANCEL_BUTTON: 'walletCancelButton',
  MAKE_PAYMENT_SUBMIT_BUTTON: 'makePaymentSubmitButton',
  MAKE_PAYMENT_CANCEL_BUTTON: 'makePaymentCancelButton',
  WALLET_AMOUNTLINE_LABEL: 'walletAmountlineLabel',
  MAKE_PAYMENT_AMOUNTLINE_LABEL: 'makePaymentAmountlineLabel',
  MAKE_ACH_PAYMENT_AMOUNTLINE_LABEL:
    'makeAchPaymentAmountlineLabel',
  WALLET_FORM_TEXT: 'selectPaymentMethodHelperText',
  EDIT_PAYMENT_SAVE_BUTTON: 'editPaymentSaveButton',
  EDIT_PAYMENT_CANCEL_BUTTON: 'editPaymentCancelButton',
  REMOVE_PAYMENT__METHOD_BUTTON: 'removePaymentMethodButton',
  REMOVE_PAYMENT_SCREEN_CANCEL_BUTTON:
    'removePaymentScreenCancelButton',
  SEND_TEXT_CUSTOMER_INFORMATION_TITLE:
    'sendTextCustomerInformationTitle',
  SEND_EMAIL_CUSTOMER_INFORMATION_TITLE:
    'sendEmailCustomerInformationTitle',
  SEND_TEXT_FIELDS_REQUIRED_SUBTITLE:
    'sendTextFieldsRequiredSubtitle',
  SEND_EMAIL_FIELDS_REQUIRED_SUBTITLE:
    'sendEmailFieldsRequiredSubtitle',
  MAKE_PAYMENT_SAVE_FOR_FUTURE_CHECKBOX_CARD:
    'makePaymentSaveForFutureCheckboxCard',
  MAKE_PAYMENT_SAVE_FOR_FUTURE_CHECKBOX_ACH:
    'makePaymentSaveForFutureCheckboxAch',
  MAKE_PAYMENT_SET_AS_DEFAULT_CHECKBOX:
    'makePaymentSetAsDefaultCheckbox',
  MAKE_PAYMENT_SET_AS_DEFAULT_CHECKBOX_ACH:
    'makeACHPaymentSetAsDefaultCheckbox',
  MAKE_PAYMENT_NICKNAME_ACH: 'makeACHPaymentNickname',
  MAKE_PAYMENT_ALL_FIELDS_REQUIRED_TITLE:
    'makePaymentAllFieldsRequired',
  ACH_MAKE_PAYMENT_ALL_FIELDS_REQUIRED_TITLE:
    'makeAchPaymentAllFieldsRequired',
  WALLET_DEFAULT_PAYMENT_METHOD_TEXT:
    'walletDefaultPaymentMethodText',
  GENERIC_ACH_BANK_NAME: 'genericAchBankName',
  DISCLAIMER_TEXT: 'disclaimerText',
  GENERIC_ID_PRIVACY_POLICY_URL: 'privacyPolicyUrl',
  GENERIC_ID_PRIVACY_POLICY_ICON: 'privacyPolicyIcon',
  TERMS_AND_CONDITIONS_URL: 'termsAndConditionsUrl',
  TERMS_AND_CONDITIONS_ICON: 'termsAndConditionsIcon',
  PAYMENT_METHOD_CHANNEL_SELECTOR_BACK_BUTTON:
    'paymentMethodChannelSelectorBackButton',
  PAYMENT_TERMS_AND_CONDITIONS_CONTENT:
    'PaymentTermsAndConditionsContent',
  PAYMENT_TERMS_AND_CONDITIONS_SUBHEADING:
    'PaymentTermsAndConditionsSubheading',
  UNIFIED_PAYMENT_METHOD_SELECTOR_TITLE:
    'unifiedPaymentMethodSelectorTitle',
  SYCURIO_PAYMENT_METHOD_AGENT_CONTROL_PANEL_TITLE:
    'sycurioPaymentMethodAgentControlPanelTitle',
  SYCURIO_PAYMENT_METHOD_AGENT_CONTROL_PANEL_SUB_TITLE:
    'sycurioPaymentMethodAgentControlPanelSubTitle',
  SYCURIO_PAYMENT_METHOD_AGENT_CONTROL_PANEL_CANCEL_BUTTON:
    'sycurioPaymentMethodAgentControlPanelCancelButton',
  SYCURIO_PAYMENT_METHOD_AGENT_CONTROL_PANEL_BACK_BUTTON:
    'sycurioPaymentMethodAgentControlPanelBackButton',
  UNIFIED_PAYMENT_METHOD_SELECTOR_SUB_TITLE:
    'unifiedPaymentMethodSelectorSubTitle',
  UNIFIED_PAYMENT_METHOD_SELECTOR_BACK_BUTTON:
    'unifiedPaymentMethodSelectorBackButton',
  CTA_ADD_PAYMENT_METHOD_TITLE: 'addPaymentMethodCtaTitle',
  CTA_REMOVE_PAYMENT_METHOD_TITLE: 'removePaymentMethodCtaTitle',
  CTA_REMOVE_PAYMENT_METHOD_DESCRIPTION:
    'removePaymentMethodCtaSubtitle',
  REMOVE_TEXT: 'removeText',
  REMOVE_PAYMENT_METHOD_NOTIFICATION: 'RemovePaymentMethod',
  CTA_MANAGE_WALLET_TITLE: 'manageWalletCtaTitle',
  CTA_MANAGE_WALLET_DESCRIPTION: 'manageWalletCtaSubtitle',
  PAYMENT_METHOD_DISPLAY_NOT_AVAILABLE:
    'paymentMethodDisplayNotAvailable',
};

export const CONTAINER_IDS = {
  CHECKOUT_CONTAINER_ID: 'checkoutContainerId',
  DRAWER_CONTAINER_ID: 'ccgDrawerContainer',
  WALLET_FORM: 'payment-form',
  PAYMENT_METHOD_LIST_WRAPPER_ELEMENT:
    'paymentMethodsListContainer',
  WALLET_MANAGEMENT_CONTAINER: 'walletManagementContainer',
  PAYMENT_METHOD_SELECTOR_WRAPPER:
    'paymentMethodSelectorWrapper',
  WALLET_MANAGEMENT_ROUTE_CONTAINER_WRAPPER:
    'walletManagementRouteContainerWrapper',
};

export const FORM_FIELD_SUFFIXES_IDS = {
  HELPER_TEXT: 'helperText',
  HINT_TEXT: 'hintText',
  FIELD_LABEL: 'fieldLabel',
};

export const ICON_IDS = {
  WARNING_CARD_ICON: 'warningCardIcon',
  NOTIFICATION_ALERT_ICON: 'notificationAlertIcon',
  SESSION_OUTCOME_ERROR_ICON: 'sessionOutcomeErrorIcon',
  SESSION_OUTCOME_CANCEL_ICON: 'sessionOutcomeCancelIcon',
  SESSION_OUTCOME_TIMEOUT_ICON: 'sessionOutcomeTimeoutIcon',
  SESSION_OUTCOME_SUCCESS_ICON: 'sessionOutcomeSuccessIcon',
  MANUFACTURER_CARD_DOLLAR_ICON: 'manufacturerCardDollarIcon',
  WALLET_DEFAULT_PAYMENT_METHOD_CHECK_ICON:
    'walletDefaultPaymentMethodCheckIcon',
  WALLET_CARD_KEBAB_ICON_BUTTON: 'walletCardKebabIconButton',
  GENERIC_ACH_BANK_ICON: 'genericAchBankIcon',
  WALLET_INVALID_PAYMENT_METHOD_WARNING_ICON:
    'walletInvalidPaymentMethodWarningIcon',
};

export const ELEMENT_IDS = {
  PAYMENT_METHOD_ACH_SAVE_BUTTON: 'paymentMethodAchSaveButton',
  PAYMENT_METHOD_ACH_CANCEL_BUTTON:
    'paymentMethodAchCancelButton',
  PAY_AND_STORE_ACH_SUBMIT_BUTTON: 'payAndStoreAchSubmitButton',
  PAY_AND_STORE_ACH_CANCEL_BUTTON: 'payAndStoreAchCancelButton',
  CTA_ADD_PAYMENT_METHOD_BUTTON: 'addPaymentMethodCtaAddButton',
  CTA_REMOVE_PAYMENT_METHOD_BUTTON:
    'removePaymentMethodCtaRemoveButton',
  WALLET_MANAGEMENT_DONE_BUTTON: 'walletManagementDoneButton',
  REMOVE_PAYMENT_METHOD_BACK_BUTTON:
    'removePaymentMethodBackButton',
  CTA_MANAGE_WALLET_BUTTON: 'manageWalletCtaButton',
  WALLET_MANAGEMENT_BACK_BUTTON: 'walletManagementBackButton',
  PAYMENT_METHOD_SELECTOR_CONFIRM_BUTTON:
    'paymentMethodSelectorConfirmButton',
  PAYMENT_METHOD_SELECTOR_CANCEL_BUTTON:
    'paymentMethodSelectorCancelButton',
  LANGUAGE_DROPDOWN_SELECTOR: 'languageDropdownSelector',
};
// never change classNames below unless translation feature is turned off
export const TRANSLATION_CLASS_NAMES = {
  TRANSLATION_CLASS_NAME: 'OneLinkTx',
  NO_TRANSLATION_CLASS_NAME: 'OneLinkNoTx',
};
