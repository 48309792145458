import { appInsights } from '../app-insights';
import { telemetryKeyPrefix } from '../utils/telemetryKeyPrefix';

import type { SycurioError } from './types';

export const logSycurioError = ({
  errorType,
  error,
  metadata = {},
}: SycurioError) => {
  const {
    code,
    message,
    accountId,
    checkoutSessionId,
    clientReference,
    merchantId,
    origin,
    semafoneCR,
  } = metadata;

  appInsights.traceError(error, {
    [telemetryKeyPrefix('sycurioErrorType')]: errorType,
    [telemetryKeyPrefix('sycurioErrorCode')]: code,
    [telemetryKeyPrefix('sycurioErrorMessage')]: message,
    [telemetryKeyPrefix('sycurioAccountId')]: accountId,
    [telemetryKeyPrefix('sycurioCheckoutSessionId')]:
      checkoutSessionId,
    [telemetryKeyPrefix('sycurioClientReference')]:
      clientReference,
    [telemetryKeyPrefix('sycurioMerchantId')]: merchantId,
    [telemetryKeyPrefix('sycurioOrigin')]: origin,
    [telemetryKeyPrefix('sycurioSemafoneCR')]: semafoneCR,
  });
};
