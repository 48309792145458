import type { FC } from 'react';
import React, { useContext, useEffect, useState } from 'react';
import type { Stripe } from '@stripe/stripe-js';

import { type ApiError } from '../../checkout-v2/types';
import noop from '../../../utils/function/noop';
import { AppContext } from '../../contextStore/AppContext';
import { NotificationContext } from '../../contextStore/NotificationContext';
import {
  getAgent,
  getExpiresAtUtc,
  getMerchantId,
  getSessionId,
  getSycurioTelephonicEntryDetails,
} from '../../../utils/session/selectors';
import { getTimeDifference } from '../../../utils/date/getTimeDifference';
import {
  type PaymentMethodStatus,
  type SetupPaymentMethodResponse,
} from '../../../services/customer/types';
import useSessionExpirationReminder from '../../../hooks/useSessionExpirationReminder';
import shouldTriggerReminder from '../../../utils/capabilities/shouldTriggerReminder';
import SycurioPhoneEntrySubmitView, {
  type SycurioPaymentMethodFormSubmitData,
} from '../sycurio-phone-entry-submit-view/SycurioPhoneEntrySubmitView';
import type ComponentEntryLocalData from '../../../shared/types/component-entry/ComponentEntryLocalData';
import type CustomerApi from '../../../services/customer/CustomerApi';
import ErrorCard from '../../../components/ErrorCard/ErrorCard';
import StripePaymentElementWrapper from '../../../components/stripe/stripe-elements-wrapper/StripePaymentElementWrapper';
import useSycurioLogger from '../hooks/useSycurioLogger';
import { generateTrackEventMetadata } from '../utilities/generateTrackEventMetadata';
import { generateEventMetadataFromSycurioUrl } from '../utilities/generateEventMetadataFromSycurioUrl';
import {
  generateSycurioData,
  isTelephonicEntryLocalDataValid,
} from '../utilities/generateSycurioSubmitObject';

export type SycurioPhoneEntrySubmitContainerProps = {
  localData: ComponentEntryLocalData;
  customerId: string;
  customerApi: typeof CustomerApi;
  onSuccess: ({
    paymentMethodId,
    showAddPaymentMethodNotificationAfterNav,
  }: {
    paymentMethodId: string;
    showAddPaymentMethodNotificationAfterNav?: boolean;
  }) => void;
  onBeforeSubmit?: () => void;
  onBackClick?: () => void;
  onCancel: () => void;
  onError?: (error: ApiError) => void;
  vendorPlatformKey: string;
};

type SycurioCreatePaymentMethodProps = {
  nickname: string;
  nameOnCard: string;
  manufacturerCard: boolean;
  isDefault: boolean;
  stripe: Stripe;
};

const SycurioPhoneEntrySubmitContainer: FC<
  SycurioPhoneEntrySubmitContainerProps
> = ({
  customerApi,
  customerId,
  localData,
  onBeforeSubmit = noop,
  onSuccess = noop,
  onCancel = noop,
  onBackClick,
  onError = noop,
  vendorPlatformKey,
}) => {
  const {
    setData,
    setSessionOutcomePollingInterval,
    originalCheckoutSessionResponse,
  } = useContext(AppContext);

  const { trackEvent, logError } = useSycurioLogger();

  const { notify } = useContext(NotificationContext);

  const [isSubmitDone, setIsSubmitDone] =
    useState<boolean>(false);

  const [isComponentUnmounting, setIsComponentUnmounting] =
    useState<boolean>(false);
  const expiresAt = getExpiresAtUtc(
    originalCheckoutSessionResponse,
  );
  const timeDifference = getTimeDifference(
    new Date(expiresAt),
    new Date(new Date().toISOString()),
  );

  const telephonicEntryDetails =
    getSycurioTelephonicEntryDetails(
      originalCheckoutSessionResponse,
    );

  const checkoutSessionId = getSessionId(
    originalCheckoutSessionResponse,
  );
  const merchantId = getMerchantId(
    originalCheckoutSessionResponse,
  );

  const agent = getAgent(originalCheckoutSessionResponse);
  const handleError = (err: string) => {
    setData({ overlayLoaderConfig: { show: false } });

    if (onBackClick) {
      onBackClick();
    }
    console.error(err);
  };

  const notifyPaymentMethodWarning = (
    setupIntentResponse?: SetupPaymentMethodResponse,
  ) => {
    if (setupIntentResponse?.data.warning) {
      setData({
        addPaymentMethodWarning:
          setupIntentResponse?.data.warning,
      });
    }
  };
  const isAuthRequired = (status: PaymentMethodStatus) => {
    return status === 'AUTH_REQUIRED';
  };
  const authorizeCard = async ({
    stripe,
    setupPaymentMethodResponse,
    setupPaymentMethodUrl,
  }: {
    stripe: Stripe;
    setupPaymentMethodResponse: SetupPaymentMethodResponse;
    setupPaymentMethodUrl: string;
  }) => {
    const { status, vendorSetupPaymentMethodSecret } =
      setupPaymentMethodResponse.data;

    if (!isAuthRequired(status)) {
      return setupPaymentMethodResponse;
    }

    if (!vendorSetupPaymentMethodSecret) {
      throw new Error(
        'Failed to start authorization due to missing vendorSetupPaymentMethodSecret',
      );
    }

    /**
     * This launches Stripe's authorization screen, when adding a card
     * - stripe.handleNextAction resolves to a confirmed success/failure
     * for the given setupIntent, without the need to confirm it via the API
     * - UI however, relies upon CCG API instead of the resolved value from the promise
     * - Note: Card authorization was introduced starting v2.9.0
     */
    await stripe.handleNextAction({
      clientSecret: vendorSetupPaymentMethodSecret,
    });

    return customerApi.confirmPaymentMethodCreation({
      url: setupPaymentMethodUrl,
      isPaymentMethodAuthorized: true,
    });
  };
  const createPaymentMethod = async ({
    nickname,
    nameOnCard,
    manufacturerCard,
    isDefault,
    stripe,
  }: SycurioCreatePaymentMethodProps) => {
    try {
      if (!localData?.paymentMethodId) {
        throw new Error('Failed to add payment method');
      }

      let createPaymentMethodResponse =
        await customerApi.createPaymentMethod(customerId, {
          paymentMethod: {
            type: 'CARD',
            vendorPaymentMethodId: localData?.paymentMethodId,
            vendor: 'STRIPE',
            default: isDefault,
            nickname,
            card: {
              nameOnCard,
              manufacturerCard,
            },
            agent,
            authRequired: true,
          },
        });

      if (
        !createPaymentMethodResponse ||
        createPaymentMethodResponse?.errors?.length
      ) {
        throw new Error('Failed to add payment method');
      }

      createPaymentMethodResponse = await authorizeCard({
        stripe,
        setupPaymentMethodResponse: createPaymentMethodResponse,
        setupPaymentMethodUrl: createPaymentMethodResponse.url,
      });

      if (
        createPaymentMethodResponse?.errors?.length ||
        !createPaymentMethodResponse?.data?.paymentMethodId
      ) {
        throw new Error('Failed to add payment method');
      }

      const { paymentMethodId } =
        createPaymentMethodResponse.data;

      // @note: by this time, the status should be in completed state and url should be available
      // the conditional check below is mostly to satisfy the type checker
      if (
        telephonicEntryDetails &&
        'url' in telephonicEntryDetails &&
        agent &&
        'userId' in agent
      ) {
        const sycurioMetadata = generateTrackEventMetadata({
          name: 'attachPaymentMethod',
          sycurioMetadataArgs: {
            sycurioFragmentUrl: telephonicEntryDetails.url,
            checkoutSessionId,
            merchantId,
          },
          paymentMethod: {
            paymentMethodId,
            customerId,
            agentId: agent.userId,
          },
        });

        if (sycurioMetadata) {
          trackEvent(sycurioMetadata);
        }
      }

      onSuccess({
        paymentMethodId,
        showAddPaymentMethodNotificationAfterNav:
          !createPaymentMethodResponse?.data.warning,
      });

      notifyPaymentMethodWarning(createPaymentMethodResponse);
    } catch (e: any) {
      onError(e?.response?.data as ApiError);
      if (e instanceof Error) {
        if (
          telephonicEntryDetails &&
          'url' in telephonicEntryDetails
        ) {
          logError({
            error: e,
            metadata: generateEventMetadataFromSycurioUrl({
              checkoutSessionId,
              merchantId,
              sycurioFragmentUrl: telephonicEntryDetails?.url,
            }),
          });
        }
        handleError(e.message);
      }
    } finally {
      setData({ overlayLoaderConfig: { show: false } });
    }
  };

  const onSubmit = ({
    nameOnCard,
    nickname,
    manufacturerCard,
    isDefault,
    stripe,
  }: SycurioPaymentMethodFormSubmitData) => {
    setData({ overlayLoaderConfig: { show: true } });
    setSessionOutcomePollingInterval(undefined);
    onBeforeSubmit();

    createPaymentMethod({
      nameOnCard,
      nickname: nickname || '',
      manufacturerCard,
      isDefault,
      stripe,
    })
      .catch(() => {
        // errors are already caught and handled inside createPaymentMethod itself
      })
      .finally(() => {
        setIsComponentUnmounting(false);
        setIsSubmitDone(true);
      });
  };

  useSessionExpirationReminder({
    expiresAt,
    notify,
    timeDifference,
    shouldTriggerReminder: shouldTriggerReminder(
      originalCheckoutSessionResponse,
    ),
  });

  useEffect(() => {
    if (isSubmitDone && !isComponentUnmounting) {
      setSessionOutcomePollingInterval(10000);
      setIsComponentUnmounting(false);
      setIsSubmitDone(false);
    }
  }, [isSubmitDone, isComponentUnmounting]);

  useEffect(() => {
    if (!customerId) {
      return;
    }

    setSessionOutcomePollingInterval(10000);

    // eslint-disable-next-line consistent-return
    return () => {
      setIsComponentUnmounting(true);
      setSessionOutcomePollingInterval(undefined);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const isValidData = isTelephonicEntryLocalDataValid(localData);

  if (!isValidData) {
    return <ErrorCard />;
  }

  return (
    <StripePaymentElementWrapper
      vendorPlatformKey={vendorPlatformKey}
    >
      <SycurioPhoneEntrySubmitView
        formTitle="Add via phone entry"
        sycurioData={generateSycurioData(localData)}
        agent={agent}
        onBackClick={onBackClick}
        onCancel={onCancel}
        onSubmit={onSubmit}
      />
    </StripePaymentElementWrapper>
  );
};

export default SycurioPhoneEntrySubmitContainer;
