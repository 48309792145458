import React, { forwardRef } from 'react';
import type {
  ChangeEventHandler,
  FocusEventHandler,
} from 'react';
import { Box } from '@mui/material';

import noop from '../../utils/function/noop';
import { FORM_FIELD_SUFFIXES_IDS } from '../../utils/element-ids';

import type { InputValueTransformersKeys } from './types/InputValueTransformers';
import inputValueTransformersMap from './transformers/inputValueTransformersMap';
import StyledInput from './styled-components/StyledInput';

export type InputFieldProps = {
  id: string;
  value: string | undefined;
  type?: 'text' | 'number';
  inputMode?: 'text' | 'numeric';
  placeholder?: string;
  disabled?: boolean;
  error?: boolean;
  errorLabelId?: string;
  required?: boolean;
  autocomplete?: 'name';
  onChange: (value: string) => void;
  onBlur?: (value: string) => void;
  maxLength?: number;
  inputValueTransformers?: InputValueTransformersKeys[];
  hintText?: string;
};

const InputField = forwardRef<HTMLInputElement, InputFieldProps>(
  (
    {
      value,
      id,
      placeholder,
      type = 'text',
      inputMode = 'text',
      disabled = false,
      error = false,
      errorLabelId = '',
      required = false,
      autocomplete,
      onChange,
      onBlur = noop,
      maxLength,
      inputValueTransformers = ['noConsecutiveSpaces'],
      hintText,
    },
    ref,
  ) => {
    const handleOnChange: ChangeEventHandler<
      HTMLInputElement
    > = (event) => {
      let transformedValue: string | undefined =
        event.currentTarget.value;

      if (inputValueTransformers.length > 0) {
        inputValueTransformers.forEach((transformerKey) => {
          const transformerFunction =
            inputValueTransformersMap[transformerKey];

          if (transformerFunction) {
            transformedValue =
              transformerFunction(transformedValue);
          }
        });
      }

      onChange(transformedValue);
    };

    const handleOnBlur: FocusEventHandler<HTMLInputElement> = (
      event,
    ) => {
      let parsedValue = event.currentTarget.value;
      if (parsedValue) {
        parsedValue = parsedValue.trim();
      }
      onBlur(parsedValue);
    };

    return (
      <Box position="relative">
        <StyledInput
          type={type}
          inputMode={inputMode}
          ref={ref}
          id={id}
          data-testid={id}
          className={error ? 'is-invalid' : ''}
          placeholder={placeholder}
          value={value}
          disabled={disabled}
          autoComplete={autocomplete}
          onChange={handleOnChange}
          onBlur={handleOnBlur}
          aria-describedby={`${id}_${FORM_FIELD_SUFFIXES_IDS.HELPER_TEXT} ${errorLabelId} ${hintText}`}
          aria-required={required}
          maxLength={maxLength}
        />
      </Box>
    );
  },
);

export default InputField;
