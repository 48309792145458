import { Grid } from '@mui/material';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import useFormConfigManager from '../../../hooks/useFormConfigManager/useFormConfigManager';
import { CANCEL_BUTTON } from '../../../shared/strings';
import BackButton from '../../../ui/BackButton/BackButton';
import Button from '../../../ui/Button/Button';
import PageTitle from '../../../ui/PageTitle/PageTitle';
import { TEXT_CONTENT_IDS } from '../../../utils/element-ids';
import {
  getMerchantId,
  getSessionId,
  getSycurioTelephonicEntryDetails,
  getSycurioTelephonicEntryErrorMessage,
} from '../../../utils/session/selectors';
import type { UnifiedPaymentFormEntryProps } from '../../UnifiedPaymentForm/UnifiedPaymentFormEntry';
import headerConfig from '../../UnifiedPaymentForm/UnifiedPaymentFormHeader/utils/headerConfig';
import useStartSycurioTelephonicEntry from '../hooks/useStartSycurioTelephonicEntry';
import type { SycurioPCIResponseType } from '../utilities/getSycurioPCIResponse';
import { getSycurioPCIResponse } from '../utilities/getSycurioPCIResponse';
import { useSycurioMessageListener } from '../hooks/useSycurioMessageListener';
import {
  hasCapturedPaymentMethodResponse,
  isMessageTypeSubmit,
} from '../utilities/hasCapturedPaymentMethodResponse';
import type { CheckoutSessionsResponse } from '../../../services/commonCheckout/types/CheckoutSessionsResponse';
import { isMessageFromSycurio } from '../utilities/isMessageFromSycurio';
import { hasSycurioPageHeight } from '../utilities/hasSycurioPageHeight';
import { getSycurioPageHeight } from '../utilities/getSycurioPageHeight';
import useSycurioAgentPageObserver from '../hooks/useSycurioAgentPageObserver';
import { hasValidPCIResponse } from '../utilities/hasValidPCIResponse';
import useSycurioLogger from '../hooks/useSycurioLogger';
import { generateTrackEventMetadata } from '../utilities/generateTrackEventMetadata';
import { TELEPHONIC_ENTRY_ERROR_MESSAGES } from '../constants/messages';

type SycurioErrorType = 'casiError' | 'sycurioIframeError';
export type SycurioErrorEventType = {
  sycurioErrorType: SycurioErrorType;
  message: string;
};
export type SycurioAgentControlPanelProps = Pick<
  UnifiedPaymentFormEntryProps,
  'userJourney' | 'backButtonLabel' | 'onBackClick'
> & {
  onCancel: () => void;
  onDone: (event: {
    sycurioPCIResponse: SycurioPCIResponseType;
  }) => void;
  onError: (errorEvent: SycurioErrorEventType) => void;
  originalCheckoutSessionResponse: CheckoutSessionsResponse;
  controlSpinner: (event: { isLoaderOn: boolean }) => void;
};

const SycurioTelephonicEntry: React.VFC<
  SycurioAgentControlPanelProps
> = ({
  userJourney,
  backButtonLabel,
  onBackClick,
  onCancel,
  onDone,
  onError,
  originalCheckoutSessionResponse,
  controlSpinner,
}) => {
  const [sycurioPageHeight, setSycurioPageHeight] = useState<
    string | undefined
  >(undefined);
  const [
    hasTimeElapseForSycurioInitMessage,
    setHasTimeElapseForSycurioInitMessage,
  ] = useState<boolean>(false);
  const { title: formTitle, subTitle } =
    headerConfig[userJourney].CARD.agent.phoneEntry;
  const checkoutSessionId = getSessionId(
    originalCheckoutSessionResponse,
  );
  const merchantId = getMerchantId(
    originalCheckoutSessionResponse,
  );

  const sycurioPageMountRef = useRef<number>(0);

  const { trackEvent, logError } = useSycurioLogger();

  const {
    SYCURIO_PAYMENT_METHOD_AGENT_CONTROL_PANEL_TITLE,
    SYCURIO_PAYMENT_METHOD_AGENT_CONTROL_PANEL_SUB_TITLE,
    SYCURIO_PAYMENT_METHOD_AGENT_CONTROL_PANEL_BACK_BUTTON,
    SYCURIO_PAYMENT_METHOD_AGENT_CONTROL_PANEL_CANCEL_BUTTON,
  } = TEXT_CONTENT_IDS;

  const telephonicEntryDetails =
    getSycurioTelephonicEntryDetails(
      originalCheckoutSessionResponse,
    );

  const isSycurioReady = useMemo(
    () =>
      telephonicEntryDetails?.status === 'IN_PROGRESS' &&
      !!telephonicEntryDetails?.url,
    [
      telephonicEntryDetails?.status === 'IN_PROGRESS' &&
        !!telephonicEntryDetails?.url,
    ],
  );

  const { sycurioFragmentUrl = '', sycurioHttpOrigin } =
    useMemo(() => {
      const sycurioUrl =
        telephonicEntryDetails?.status === 'IN_PROGRESS'
          ? telephonicEntryDetails?.url
          : undefined;
      if (!sycurioUrl) {
        return {
          sycurioHttpOrigin: undefined,
          sycurioFragmentUrl: undefined,
        };
      }
      const url = new URL(sycurioUrl);
      return {
        sycurioHttpOrigin: url.origin,
        sycurioFragmentUrl: url.href,
      };
    }, [
      telephonicEntryDetails?.status === 'IN_PROGRESS' &&
        telephonicEntryDetails?.url,
    ]);

  const appInsightsMetadata = generateTrackEventMetadata({
    sycurioMetadataArgs: {
      sycurioFragmentUrl,
      checkoutSessionId,
      merchantId,
    },
    name: 'cancelFragment',
  });

  useSycurioMessageListener({
    callback: useCallback(
      (event) => {
        if (
          sycurioHttpOrigin &&
          !isMessageFromSycurio({ event, sycurioHttpOrigin })
        ) {
          return;
        }

        if (typeof event.data === 'string') {
          if (hasSycurioPageHeight(event)) {
            const iframePageHeight = getSycurioPageHeight(
              event.data,
            );
            setSycurioPageHeight(iframePageHeight);
          }

          if (!isMessageTypeSubmit(event.data)) {
            return;
          }

          if (
            hasCapturedPaymentMethodResponse(event.data) &&
            hasValidPCIResponse(event.data)
          ) {
            const sycurioPCIResponse = getSycurioPCIResponse(
              event.data,
            );
            onDone({ sycurioPCIResponse });
            return;
          }
          logError({
            error: new Error(
              `Sycurio PCI response is invalid: ${JSON.stringify(
                event.data,
              )}`,
            ),
            metadata: appInsightsMetadata.sycurioMetadata,
          });
          console.error(
            'Form was submitted but response was malformed',
          );
        }
      },
      [sycurioHttpOrigin],
    ),
  });

  const hasCasiSessionFailed = useMemo(
    () => telephonicEntryDetails?.status === 'FAILED',
    [telephonicEntryDetails?.status],
  );

  /** note: our assumption is if the casi status call is IN_PROGRESS
   *  and we have received the sycurio fragment url, plus
   *  we have received a message from the iframe a page height after 2 seconds
   *  then the iframe has successfully loaded.
   */
  const hasSycurioIframeFailedToLoad = useMemo(
    () =>
      isSycurioReady &&
      sycurioPageMountRef.current === 1 &&
      !sycurioPageHeight &&
      !!sycurioHttpOrigin &&
      hasTimeElapseForSycurioInitMessage,
    [
      isSycurioReady,
      sycurioPageMountRef.current,
      sycurioPageHeight,
      sycurioHttpOrigin,
      hasTimeElapseForSycurioInitMessage,
    ],
  );

  const sycurioErrorMessage = useMemo(() => {
    if (hasCasiSessionFailed) {
      return getSycurioTelephonicEntryErrorMessage(
        originalCheckoutSessionResponse,
      );
    }

    return 'Failed to load telephonic entry. Please try again.';
  }, [hasCasiSessionFailed, originalCheckoutSessionResponse]);

  useEffect(() => {
    if (sycurioPageMountRef.current === 0) {
      sycurioPageMountRef.current += 1;
    }
    /** note: CC-12298 requires that if an iframe fails to load
     *  we should display an error. Iframe onerror event is not
     *  firing. Access to the iframe content is blocked due to
     *  cross-origin restrictions. We are using a timeout to
     *  check if the sycurio iframe has sent a postMessage even
     *  at initial load providing the height of the iframe.
     *  There is an unknown delay so I chose 2 seconds as a
     *  reasonable time to wait for the message.
     *
     *  Increased the timeout to 3 seconds to account for distance
     *  of user from the server - we noticed that offshore was
     *  seeing the error message consistently, but not via vdi.
     */
    let timeOutId: ReturnType<typeof setTimeout>;
    if (sycurioPageMountRef.current === 1 && isSycurioReady) {
      timeOutId = setTimeout(() => {
        setHasTimeElapseForSycurioInitMessage(true);
      }, 5000);
    }

    return () => {
      if (timeOutId) {
        clearTimeout(timeOutId);
      }
    };
  }, [isSycurioReady]);

  const onBackHandler = useCallback(() => {
    trackEvent(appInsightsMetadata);
    onBackClick?.();
  }, [appInsightsMetadata.sycurioMetadata.checkoutSessionId]);

  const onCancelHandler = useCallback(() => {
    if (appInsightsMetadata) {
      trackEvent(appInsightsMetadata);
    }
    onCancel();
  }, [appInsightsMetadata?.sycurioMetadata.checkoutSessionId]);

  const onErrorHandler = useCallback(
    ({ sycurioErrorType, message }: SycurioErrorEventType) => {
      const { sycurioMetadata } = appInsightsMetadata ?? {
        sycurioMetadata: undefined,
      };
      logError({
        error: new Error(
          TELEPHONIC_ENTRY_ERROR_MESSAGES[sycurioErrorType],
        ),
        metadata: {
          code: sycurioErrorType,
          message,
          ...sycurioMetadata,
        },
      });
      onError({
        sycurioErrorType,
        message,
      });
    },
    [appInsightsMetadata.sycurioMetadata.checkoutSessionId],
  );

  const { title, titleTestId, showActionsOnForm } =
    useFormConfigManager({
      title: formTitle,
      titleTestId:
        SYCURIO_PAYMENT_METHOD_AGENT_CONTROL_PANEL_TITLE,
      backActionConfig: {
        label: backButtonLabel,
        testId:
          SYCURIO_PAYMENT_METHOD_AGENT_CONTROL_PANEL_BACK_BUTTON,
        handler: onBackHandler,
      },
      secondaryActionConfig: {
        label: CANCEL_BUTTON,
        handler: onCancelHandler,
        testId:
          SYCURIO_PAYMENT_METHOD_AGENT_CONTROL_PANEL_CANCEL_BUTTON,
      },
      showBackButton: !!onBackClick,
    });

  useStartSycurioTelephonicEntry({
    checkoutSessionId,
    startSpinner: useCallback(() => {
      controlSpinner({ isLoaderOn: true });
    }, []),
    onError: onErrorHandler,
    sycurioErrorMessage,
  });

  useSycurioAgentPageObserver({
    hasCasiSessionFailed,
    hasSycurioIframeFailedToLoad,
    stopSpinner: () => {
      controlSpinner({ isLoaderOn: false });
    },
    onError: onErrorHandler,
    hasTimeElapseForSycurioInitMessage,
  });

  return (
    <Grid
      container
      flexDirection="column"
      flexWrap="nowrap"
      height="100%"
      spacing={1}
    >
      <Grid item>
        {showActionsOnForm && onBackClick ? (
          <BackButton
            label={backButtonLabel}
            onClick={onBackHandler}
            testId={
              SYCURIO_PAYMENT_METHOD_AGENT_CONTROL_PANEL_BACK_BUTTON
            }
          />
        ) : null}
        <PageTitle
          title={title}
          titleId={titleTestId}
          subTitle={subTitle}
          subTitleId={
            SYCURIO_PAYMENT_METHOD_AGENT_CONTROL_PANEL_SUB_TITLE
          }
        />
      </Grid>
      <Grid
        item
        flex={1}
      >
        {isSycurioReady ? (
          <iframe
            src={sycurioFragmentUrl}
            title="sycurio-iframe"
            data-testid="sycurio-iframe"
            style={{
              border: 'none',
              flexGrow: 1,
              margin: 0,
              padding: 0,
              width: '100%',
              height: sycurioPageHeight,
            }}
          />
        ) : null}
      </Grid>
      {showActionsOnForm ? (
        <Grid
          item
          container
          direction="column"
        >
          <Button
            isLoading={false}
            onClick={onCancelHandler}
            variant="contained"
            color="secondary"
            id={
              SYCURIO_PAYMENT_METHOD_AGENT_CONTROL_PANEL_CANCEL_BUTTON
            }
          >
            {CANCEL_BUTTON}
          </Button>
        </Grid>
      ) : null}
    </Grid>
  );
};

export default SycurioTelephonicEntry;
