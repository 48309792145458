export type SycurioPCIResponseType = {
  lastFour: string;
  expYear: string;
  expMonth: string;
  cardType: string;
  avsPostalCode: string;
  token: string;
  cardHolderName: string;
};

export const getSycurioPCIResponse = (
  sycurioEventData: string,
): SycurioPCIResponseType => {
  const { data } = JSON.parse(sycurioEventData);
  const {
    token,
    cardHolderName,
    lastFour,
    expYear,
    expMonth,
    cardType,
    avsPostalCode,
  } = data;
  return {
    token,
    cardHolderName,
    lastFour,
    expYear,
    expMonth,
    cardType,
    avsPostalCode,
  };
};
