import React, {
  useEffect,
  useState,
  useContext,
  useCallback,
} from 'react';
import { Box, Grid, Typography, styled } from '@mui/material';

import { AppContext } from '../../../contextStore/AppContext';
import { removePaymentMethodService } from '../../../../services/commonCheckout/removePaymentMethodService';
import {
  TEXT_CONTENT_IDS,
  ELEMENT_IDS,
  TITLE_IDS,
} from '../../../../utils/element-ids';
import Button from '../../../../ui/Button/Button';
import { getAgent } from '../../../../utils/session/selectors';
import { formatPaymentMethods } from '../Wallet/utils';
import BackButton from '../../../../ui/BackButton/BackButton';
import useFormConfigManager from '../../../../hooks/useFormConfigManager/useFormConfigManager';
import PaymentMethodDetails from '../../../../components/PaymentMethodButton/PaymentMethodDetails';
import Title from '../../../../ui/Title/Title';
import NotificationAlert from '../../../../ui/NotificationAlert/NotificationAlert';

import type { RemovePaymentMethodPropType } from './RemovePaymentMethodPropType';

const REMOVE_PAYMENT_METHOD_BUTTON_LABEL = 'Remove';
const CANCEL_BUTTON_LABEL = 'Cancel';
const BACK_BUTTON_LABEL = 'Back';

const StyledPaymentMethodContainer = styled(Box)(
  ({ theme }) => `
  width: 100%;
  min-height: 74px;
  padding: 13px 16px;
  border-radius: 8px;
  border: 1px solid #CBCCCD;
  gap: 12px;
  font-size: ${theme.typography.body1.fontSize};
  font-weight: ${theme.typography.body1.fontWeight};
  line-height: ${theme.typography.body1.lineHeight};
  text-align: left;
  box-sizing: border-box;
  background-color: ${theme.palette.background.default};
`,
);

const DefaultPaymentMethodValues = {
  description: '',
  last4: '',
};

const RemovePaymentMethod: React.FC<
  RemovePaymentMethodPropType
> = ({ onCancel, onComplete, paymentMethodId, onBackClick }) => {
  const [isAttemptingDelete, setIsAttemptingDelete] =
    useState(false);

  const [isDeleteSuccessful, setIsDeleteSuccessful] = useState<
    boolean | null
  >(null);

  const {
    paymentMethods,
    setSelectedPaymentMethodId,
    originalCheckoutSessionResponse,
  } = useContext(AppContext);
  const paymentMethod =
    formatPaymentMethods(
      paymentMethods.filter(
        (_paymentMethod) =>
          _paymentMethod.id === paymentMethodId,
      ),
    )[0] || DefaultPaymentMethodValues;

  const NOTIFICATION_PROPS = {
    title: 'You are about to remove this payment method.',
    message:
      ' If you remove this payment method, it will no longer be saved to your wallet.',
  };

  const onRemoveHandler = async () => {
    setIsDeleteSuccessful(null);
    setIsAttemptingDelete(true);
    let isDeletePromiseSuccessful: boolean | null = null;
    try {
      isDeletePromiseSuccessful =
        await removePaymentMethodService({
          paymentMethodId,
          data: {
            agent: getAgent(originalCheckoutSessionResponse),
          },
        });
    } catch (err) {
      isDeletePromiseSuccessful = false;
    }
    setSelectedPaymentMethodId('');
    setIsAttemptingDelete(false);
    setIsDeleteSuccessful(isDeletePromiseSuccessful);
  };

  const handleRemovePaymentMethodSync = useCallback(() => {
    /**
     * the success and failures are handled inside onRemoveHandler
     */
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    onRemoveHandler();
  }, []);

  useEffect(() => {
    if (isDeleteSuccessful) {
      onComplete();
    }
  }, [isDeleteSuccessful]);

  const { title, showActionsOnForm } = useFormConfigManager({
    title: 'Remove payment method',
    titleTestId: TITLE_IDS.REMOVE_PAYMENT_METHOD_TITLE,
    backActionConfig: {
      label: BACK_BUTTON_LABEL,
      handler: onBackClick,
      testId: ELEMENT_IDS.REMOVE_PAYMENT_METHOD_BACK_BUTTON,
    },
    primaryActionConfig: {
      label: REMOVE_PAYMENT_METHOD_BUTTON_LABEL,
      handler: handleRemovePaymentMethodSync,
      testId: TEXT_CONTENT_IDS.REMOVE_PAYMENT__METHOD_BUTTON,
    },
    secondaryActionConfig: {
      label: CANCEL_BUTTON_LABEL,
      handler: useCallback(() => {
        onCancel();
      }, []),
      testId:
        TEXT_CONTENT_IDS.REMOVE_PAYMENT_SCREEN_CANCEL_BUTTON,
    },
  });

  return (
    <Box margin={2}>
      {showActionsOnForm && onBackClick ? (
        <BackButton
          label={BACK_BUTTON_LABEL}
          onClick={onBackClick}
          testId={ELEMENT_IDS.REMOVE_PAYMENT_METHOD_BACK_BUTTON}
        />
      ) : null}
      <Box sx={{ marginTop: '24px' }}>
        <Title
          title={title}
          id={TITLE_IDS.REMOVE_PAYMENT_METHOD_TITLE}
        />
      </Box>
      <Grid
        container
        direction="column"
        sx={{
          gap: (theme) => theme.spacing(3),
        }}
      >
        <Grid
          sx={{
            flex: 1,
            position: 'relative',
            paddingTop: '24px',
            boxSizing: 'border-box',
            borderRadius: '12px',
          }}
        >
          <NotificationAlert
            severity="warning"
            title={NOTIFICATION_PROPS.title}
            message={NOTIFICATION_PROPS.message}
            id={
              TEXT_CONTENT_IDS.REMOVE_PAYMENT_METHOD_NOTIFICATION
            }
          />
        </Grid>
        <StyledPaymentMethodContainer
          display="flex"
          flexDirection="column"
          justifyContent="center"
        >
          <PaymentMethodDetails
            paymentMethod={paymentMethod}
            showCaIcon={false}
          />
        </StyledPaymentMethodContainer>
        <Typography
          style={{ fontWeight: 700, marginRight: '4px' }}
          data-testid={TEXT_CONTENT_IDS.REMOVE_TEXT}
        >
          Would you like to continue?
        </Typography>
        {showActionsOnForm && (
          <Grid
            container
            direction="column"
            sx={{
              gap: (theme) => theme.spacing(2),
            }}
          >
            <Button
              color="primary"
              variant="contained"
              name="remove"
              testId={
                TEXT_CONTENT_IDS.REMOVE_PAYMENT__METHOD_BUTTON
              }
              isLoading={isAttemptingDelete}
              fullWidth
              onClick={handleRemovePaymentMethodSync}
            >
              {REMOVE_PAYMENT_METHOD_BUTTON_LABEL}
            </Button>
            <Button
              name="cancel"
              color="secondary"
              variant="contained"
              testId={
                TEXT_CONTENT_IDS.REMOVE_PAYMENT_SCREEN_CANCEL_BUTTON
              }
              onClick={onCancel}
              disabled={isAttemptingDelete}
            >
              {CANCEL_BUTTON_LABEL}
            </Button>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default RemovePaymentMethod;
