import type { SycurioBaseMetadata } from './generateTrackEventMetadata';

export const generateEventMetadataFromSycurioUrl = (
  sycurioMetadata: SycurioBaseMetadata,
) => {
  const { sycurioFragmentUrl, checkoutSessionId, merchantId } =
    sycurioMetadata;

  let sycurioMetadataObject;

  if (sycurioFragmentUrl) {
    const url = new URL(sycurioFragmentUrl);
    const { origin } = url;
    const params = new URLSearchParams(url.search);
    const clientReference = params.get('clientReference') ?? '';
    const accountId = params.get('accountId') ?? '';
    const semafoneCR = params.get('semafoneCR') ?? '';

    sycurioMetadataObject = {
      origin,
      accountId,
      clientReference,
      semafoneCR,
    };
  }

  return {
    ...sycurioMetadataObject,
    checkoutSessionId,
    merchantId,
  };
};
