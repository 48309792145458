import type ComponentEntryLocalData from '../../../shared/types/component-entry/ComponentEntryLocalData';
import type { TelephonicEntryType } from '../../../shared/types/component-entry/ComponentEntryLocalData';

export function isTelephonicEntryLocalDataValid({
  telephonicEntry,
}: ComponentEntryLocalData): boolean {
  return (
    !!telephonicEntry &&
    (
      [
        'cardHolderName',
        'lastFour',
        'expYear',
        'expMonth',
        'cardType',
      ] as (keyof TelephonicEntryType)[]
    ).every((key) => !!telephonicEntry[key])
  );
}

export function generateSycurioData({
  telephonicEntry,
}: ComponentEntryLocalData) {
  const initalData = {
    cardHolderName: '',
    lastFour: '',
    expYear: '',
    expMonth: '',
    cardType: '',
    avsPostalCode: '',
    country: '',
    nickname: '',
  };
  if (!telephonicEntry) {
    throw new Error('telephonicEntry is required');
  }
  return Object.entries(telephonicEntry)
    .filter(([key, _]) => Object.keys(initalData).includes(key))
    .reduce((generatedSycurioData, [key, val]) => {
      return {
        ...generatedSycurioData,
        [key]: val,
      };
    }, initalData);
}
