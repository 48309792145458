import commonSycurioTelephonicEntryService from '../../../services/commonCheckout/commonSycurioTelephonicEntryService';
import type ComponentEntryLocalData from '../../../shared/types/component-entry/ComponentEntryLocalData';

import type GetBackButtonDetailsReturn from './types/GetBackButtonDetailsReturn';

export type GetBackButtonHandlerProps = {
  componentEntryLocalData: ComponentEntryLocalData;
  setComponentEntryLocalData: (
    value: React.SetStateAction<ComponentEntryLocalData>,
  ) => void;
  closeNotification: () => void;
  backButtonDetails: GetBackButtonDetailsReturn;
  onBackButtonParentHandler?: () => void;
  isSycurioPhoneEntryForm?: boolean;
  clearError?: () => void;
  shouldTriggerSycurioDeleteCall?: boolean;
};

export const getBackButtonHandler = ({
  backButtonDetails,
  componentEntryLocalData,
  setComponentEntryLocalData,
  closeNotification,
  onBackButtonParentHandler,
  shouldTriggerSycurioDeleteCall,
  isSycurioPhoneEntryForm,
  clearError,
}: GetBackButtonHandlerProps) => {
  if (backButtonDetails.showBackButton) {
    const localDataOnBackClick: ComponentEntryLocalData = {
      ...componentEntryLocalData,
      isBackToChannelSelection: false,
    };

    if (
      backButtonDetails.scenario === 'BACK_TO_CHANNEL_SELECTOR'
    ) {
      return () => {
        closeNotification();
        setComponentEntryLocalData({
          ...localDataOnBackClick,
          isBackToChannelSelection: true,
          paymentMethodId: undefined,
          selectedChannelType: undefined,
          telephonicEntry: undefined,
        });
        clearError?.();
        if (isSycurioPhoneEntryForm) {
          // calling delete is only needed on viewState === 'sycurio_telephonic_entry'
          commonSycurioTelephonicEntryService({
            checkoutSessionId:
              componentEntryLocalData.checkoutSessionId,
            method: 'DELETE',
            eventType: 'onBackClick',
          }).catch(() => ({}));
        }
      };
    } else if (
      backButtonDetails.scenario === 'BACK_TO_WALLET' ||
      backButtonDetails.scenario === 'BACK_TO_WALLET_MANAGEMENT'
    ) {
      /** note: handles scenario where only channel available is telephonic-entry
       *  and user clicks back button. In this case, we need to DELETE the sycurio phone
       *  entry fragment.
       */
      return () => {
        if (shouldTriggerSycurioDeleteCall) {
          // calling delete is only needed on viewState === 'sycurio_telephonic_entry'
          commonSycurioTelephonicEntryService({
            checkoutSessionId:
              componentEntryLocalData.checkoutSessionId,
            method: 'DELETE',
            eventType: 'onBackClick',
          }).catch(() => ({}));
        }
        onBackButtonParentHandler?.();
      };
    } else if (
      backButtonDetails.scenario ===
      'BACK_TO_SYCURIO_TELEPHONIC_ENTRY'
    ) {
      // TODO: Do we need this at all?
      // need to re-initialize the sycurio phone entry form
      return () => {
        closeNotification();
        setComponentEntryLocalData({
          ...localDataOnBackClick,
          paymentMethodId: undefined,
          telephonicEntry: {
            hasCapturedSycurioPhoneEntry: false,
          },
        });
        clearError?.();
        commonSycurioTelephonicEntryService({
          checkoutSessionId:
            componentEntryLocalData.checkoutSessionId,
          eventType: 'startSycurio',
        }).catch(() => ({}));
      };
    } else {
      return undefined;
    }
  } else {
    return undefined;
  }
};
