import { useMemo } from 'react';

import { logError } from '../../../services/AppInsights/Error/log-error';
import type { SycurioError } from '../../../services/AppInsights/Error/types';
import { appInsights } from '../../../services/AppInsights/app-insights';
import type { SycurioMetadataArgs } from '../utilities/transformSycurioMetadata';
import { transformSycurioMetadata } from '../utilities/transformSycurioMetadata';

export type SycurioLogEventType =
  | 'insertFragment'
  | 'cancelFragment'
  | 'attachPaymentMethod';

type TrackEventArgs = SycurioMetadataArgs &
  (
    | {
        name: Exclude<
          SycurioLogEventType,
          'attachPaymentMethod'
        >;
      }
    | ({
        name: Extract<
          SycurioLogEventType,
          'attachPaymentMethod'
        >;
      } & {
        paymentMethod: {
          paymentMethodId: string;
          customerId: string;
          agentId: string;
        };
      })
  );
const trackEvent = (payload: TrackEventArgs) => {
  const { name, ...sycurioMetadata } = payload;
  appInsights.trackEvent({
    name,
    properties: transformSycurioMetadata(sycurioMetadata),
  });
};

const logSycurioError = ({
  error,
  metadata,
}: Omit<SycurioError, 'errorType'>) => {
  logError({ errorType: 'sycurio_error', error, metadata });
};

const useSycurioLogger = () => {
  const memoizedTrackEvent = useMemo(() => trackEvent, []);
  const memoizedLogError = useMemo(() => logSycurioError, []);

  return {
    trackEvent: memoizedTrackEvent,
    logError: memoizedLogError,
  };
};

export default useSycurioLogger;
