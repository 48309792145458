// Dropdown.tsx
import React from 'react';
import { Box, InputLabel, Select } from '@mui/material';
import type { SelectChangeEvent } from '@mui/material';

import {
  TRANSLATION_CLASS_NAMES,
  ELEMENT_IDS,
} from '../../utils/element-ids';

interface DropdownProps {
  value: string | undefined;
  onChange: (event: SelectChangeEvent) => void;
  testid?: string;
  isUsedByChildSession?: boolean;
}

const Dropdown: React.FC<DropdownProps> = ({
  value,
  onChange,
  testid,
  isUsedByChildSession = false,
}) => {
  return (
    <Box>
      {isUsedByChildSession ? (
        <InputLabel
          id="languagedropdownLabel"
          data-testid="languagedropdownLabel"
          sx={{
            fontSize: '.93rem',
            marginBottom: '8px',
            color: '#4B4D4F',
          }}
        >
          Language preference
        </InputLabel>
      ) : null}

      <Select
        labelId="languagedropdownLabel"
        value={value}
        variant="outlined"
        onChange={onChange}
        sx={{ width: '100%' }}
        className={
          TRANSLATION_CLASS_NAMES.NO_TRANSLATION_CLASS_NAME
        }
        data-testid={`${ELEMENT_IDS.LANGUAGE_DROPDOWN_SELECTOR}-${testid}`}
      >
        <option value="en">English</option>
        <option value="es">Español (Spanish)</option>
      </Select>
    </Box>
  );
};

export default Dropdown;
