import type { Method } from 'axios';

import { httpClientService } from '../settingsApi/httpClientService';

type SycurioEventType =
  | 'startSycurio'
  | 'onDone'
  | 'onCancel'
  | 'onBackClick'
  | 'onError';
export default async function commonSycurioTelephonicEntryService({
  checkoutSessionId,
  method = 'POST',
  eventType,
}: {
  checkoutSessionId: string;
  method?: Method;
  eventType: SycurioEventType;
}) {
  let data:
    | Record<'context', 'PAYMENT_METHOD_TOKENIZED' | 'CANCELLED'>
    | undefined;
  if (
    ['onCancel', 'onBackClick', 'onError'].includes(eventType)
  ) {
    data = {
      context: 'CANCELLED',
    };
  }
  if (eventType === 'onDone') {
    data = {
      context: 'PAYMENT_METHOD_TOKENIZED',
    };
  }

  const httpClient = httpClientService.getHttpClient({
    api: 'WALLET',
  });

  return httpClient.request({
    url: `/checkout-sessions/${checkoutSessionId}/telephonic-entry`,
    method: [
      'onDone',
      'onCancel',
      'onBackClick',
      'onError',
    ].includes(eventType)
      ? 'DELETE'
      : method,
    data,
  });
}
