import capitalizeFirstLetter from './capitalizeFirstLetter';

const titleCase = ({
  str,
  delimiter = ' ',
}: {
  str: string;
  delimiter?: string;
}) => {
  return str
    .split(delimiter)
    .map((word) => capitalizeFirstLetter(word))
    .join(delimiter);
};

export default titleCase;
