import React, { useCallback, useState } from 'react';
import type { FC } from 'react';
import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { type Stripe } from '@stripe/stripe-js';
import { useStripe } from '@stripe/react-stripe-js';

import PageTitle from '../../../ui/PageTitle/PageTitle';
import BackButton from '../../../ui/BackButton/BackButton';
import FormField from '../../../ui/FormField/FormField';
import { CANCEL_BUTTON } from '../../../shared/strings';
import noop from '../../../utils/function/noop';
import Button from '../../../ui/Button/Button';
import useFormConfigManager from '../../../hooks/useFormConfigManager/useFormConfigManager';
import {
  TEXT_CONTENT_IDS,
  TITLE_IDS,
} from '../../../utils/element-ids';
import { SETUP_PAYMENT_METHOD_BUTTON_TEXT } from '../../add-payment-method/dev';
import fieldHasCardNumber from '../../../utils/function/fieldHasCardNumber';
import {
  NICKNAME_ON_CARD_CANNOT_INCLUDE_CARD_NUMBER_ERROR,
  PAYMENT_METHOD_SETTINGS_TITLE,
} from '../../pay-and-store/constants';
import { isInvalidNickname } from '../../../utils/function/nicknameUtils';
import { type Agent } from '../../../services/commonCheckout/types/CheckoutSessionsResponse';
import Checkbox from '../../../ui/Checkbox/Checkbox';
import DropdownV2 from '../../../ui/Dropdown/DropdownV2';
import titleCase from '../../../utils/function/titleCase';

type SycurioPhoneEntryBaseProps = {
  nickname?: string;
  cardHolderName: string;
  manufacturerCard: boolean;
  isDefault: boolean;
  cardType: string;
  lastFour: string;
  expYear: string;
  expMonth: string;
  avsPostalCode: string;
};

export type SycurioSubmitFormData =
  SycurioPhoneEntryBaseProps & {
    token: string;
    hasCapturedSycurioPhoneEntry: boolean;
    country?: string;
  };
type SycurioPaymentMethodFormKeys = keyof SycurioSubmitFormData;
type SycurioPaymentMethodFormValues =
  SycurioSubmitFormData[keyof SycurioSubmitFormData];

type SycurioSubmitFormErrors = Partial<
  Record<keyof SycurioSubmitFormData, string>
>;

export type SycurioPhoneEntrySubmitProps = {
  formTitle: string;
  sycurioData: Omit<
    SycurioSubmitFormData,
    | 'token'
    | 'hasCapturedSycurioPhoneEntry'
    | 'manufacturerCard'
    | 'isDefault'
    | 'nickname'
  >;
  onBackClick?: () => void;
  onCancel: () => void;
  onSubmit: (data: SycurioPaymentMethodFormSubmitData) => void;
  agent: Agent | null;
};
const validate = ({
  nickname,
}: SycurioPhoneEntryBaseProps): SycurioSubmitFormErrors => {
  const formErrors: SycurioSubmitFormErrors = {};

  if (isInvalidNickname(nickname)) {
    formErrors.nickname = 'Please enter a valid card nickname.';
  }
  if (nickname && fieldHasCardNumber(nickname)) {
    formErrors.nickname =
      NICKNAME_ON_CARD_CANNOT_INCLUDE_CARD_NUMBER_ERROR;
  }

  return formErrors;
};

export type SycurioPaymentMethodFormSubmitData = {
  nameOnCard: string;
  nickname?: string;
  manufacturerCard: boolean;
  isDefault: boolean;
  stripe: Stripe;
};

const SycurioPhoneEntrySubmitView: FC<
  SycurioPhoneEntrySubmitProps
> = ({
  formTitle,
  sycurioData,
  onBackClick,
  onCancel = noop,
  onSubmit,
  agent,
}) => {
  const [formErrors, setFormErrors] =
    useState<SycurioSubmitFormErrors>({});

  const [formData, setFormData] =
    useState<SycurioPhoneEntryBaseProps>({
      cardHolderName: sycurioData.cardHolderName,
      nickname: '',
      manufacturerCard: false,
      isDefault: false,
      cardType: sycurioData.cardType,
      lastFour: sycurioData.lastFour,
      expYear: sycurioData.expYear,
      expMonth: sycurioData.expMonth,
      avsPostalCode: sycurioData.avsPostalCode,
    });

  const setFormFields = (
    fields: Partial<SycurioSubmitFormData>,
  ) => {
    setFormData((prevState) => ({
      ...prevState,
      ...fields,
    }));
  };
  const stripe = useStripe();
  const handleOnSubmit = useCallback(() => {
    const validations = validate(formData);
    setFormErrors(validations);
    if (!stripe || Object.keys(validations).length) {
      return;
    }

    onSubmit({
      ...sycurioData,
      nickname: formData.nickname,
      nameOnCard: formData.cardHolderName,
      manufacturerCard: formData.manufacturerCard,
      isDefault: formData.isDefault,
      stripe,
    });
  }, [
    formData.nickname,
    formData.cardHolderName,
    formData.manufacturerCard,
    formData.isDefault,
    sycurioData.cardType,
    sycurioData.country,
    sycurioData.expMonth,
    sycurioData.expYear,
    sycurioData.lastFour,
    sycurioData.avsPostalCode,
  ]);
  const handleOnChange = (
    key: SycurioPaymentMethodFormKeys,
    value: SycurioPaymentMethodFormValues,
  ) => {
    setFormFields({ [key]: value });

    const isUpdatedToManufacturerCard =
      key === 'manufacturerCard' && value;

    if (isUpdatedToManufacturerCard) {
      setFormFields({ isDefault: false });
    }
  };
  const handleOnBlur = (
    key: SycurioPaymentMethodFormKeys,
    value: SycurioPaymentMethodFormValues,
  ) => {
    const newFormValues = { ...formData, [key]: value };

    const validationResult = validate(newFormValues);

    setFormErrors(validationResult);

    setFormFields(newFormValues);
  };

  const { showActionsOnForm, title, titleTestId } =
    useFormConfigManager({
      title: formTitle,
      titleTestId: TITLE_IDS.MAIN_PAGE_TITLE,
      backActionConfig: {
        label: 'Back',
        testId: TEXT_CONTENT_IDS.ADD_PAYMENT_METHOD_BACK_BUTTON,
        handler: onBackClick,
      },
      primaryActionConfig: {
        label: SETUP_PAYMENT_METHOD_BUTTON_TEXT,
        testId: TEXT_CONTENT_IDS.ADD_PAYMENT_METHOD_SAVE_BUTTON,
        handler: handleOnSubmit,
      },
      secondaryActionConfig: {
        label: CANCEL_BUTTON,
        testId:
          TEXT_CONTENT_IDS.ADD_PAYMENT_METHOD_CANCEL_BUTTON,
        handler: onCancel,
      },
      showBackButton: !!onBackClick,
    });

  return (
    <Box>
      {showActionsOnForm && onBackClick ? (
        <BackButton
          label="Back"
          onClick={onBackClick}
        />
      ) : null}
      <PageTitle
        title={title}
        titleId={titleTestId}
        subTitle="Add a payment method using phone entry"
      />
      <Box sx={{ my: '24px' }}>
        <Typography variant="body2">
          All fields are required unless marked optional.
        </Typography>
      </Box>
      <FormField
        id="nameOnCard"
        label="Name on card"
        placeholder=""
        value={formData.cardHolderName}
        errorMessage={formErrors.cardHolderName}
        required
        autocomplete="name"
        onChange={noop}
        disabled
      />
      <FormField
        id="lastFour"
        label="Card type"
        value={titleCase({ str: formData.cardType })}
        disabled
        onChange={noop}
      />
      <FormField
        id="cardNumber"
        label="Card number"
        value={formData.lastFour}
        disabled
        onChange={noop}
      />

      <Grid
        container
        className="gridCell"
        spacing={2}
      >
        <Grid
          item
          xs={6}
        >
          <FormField
            id="expiration"
            label="Expiration"
            inputMode="numeric"
            hintText="(MM / YY)"
            placeholder="MM / YY"
            disabled
            value={`${formData.expMonth} / ${formData.expYear}`}
            required
            onChange={noop}
          />
        </Grid>
        <Grid
          item
          xs={6}
        >
          <FormField
            id="cvc"
            type="number"
            inputMode="numeric"
            label="CVC"
            placeholder="***"
            disabled
            value="***"
            required
            onChange={noop}
          />
        </Grid>
      </Grid>
      {sycurioData.avsPostalCode !== '' && (
        <>
          <DropdownV2
            value="United States"
            disabled
            id="country"
            label="Country"
          />
          <FormField
            id="zip"
            label="ZIP"
            placeholder="Jane Smith"
            value={formData.avsPostalCode}
            required
            disabled
            autocomplete="name"
            onChange={noop}
          />
        </>
      )}
      <Grid
        container
        direction="column"
        sx={{
          gap: '16px',
          marginTop: '16px',
        }}
      >
        {agent ? (
          <Grid>
            <Typography
              id="manufacturerCardLabel"
              style={{
                fontWeight: 700,
              }}
              data-testid={
                TEXT_CONTENT_IDS.MANUFACTURER_CARD_LABEL
              }
            >
              Is this a manufacturer card?
            </Typography>
            <RadioGroup
              row
              value={formData.manufacturerCard ? 'Yes' : 'No'}
            >
              <FormControlLabel
                value="Yes"
                control={
                  <Radio
                    data-testid={
                      TEXT_CONTENT_IDS.MANUFACTURER_CARD_OPTION_RADIO_BTN_YES
                    }
                    color="primary"
                    onChange={() => {
                      handleOnChange('manufacturerCard', true);
                    }}
                  />
                }
                label="Yes"
                data-testid={
                  TEXT_CONTENT_IDS.MANUFACTURER_CARD_OPTION_YES
                }
              />

              <FormControlLabel
                value="No"
                control={
                  <Radio
                    color="primary"
                    data-testid={
                      TEXT_CONTENT_IDS.MANUFACTURER_CARD_OPTION_RADIO_BTN_NO
                    }
                    onChange={() => {
                      handleOnChange('manufacturerCard', false);
                    }}
                  />
                }
                label="No"
                data-testid={
                  TEXT_CONTENT_IDS.MANUFACTURER_CARD_OPTION_NO
                }
              />
            </RadioGroup>
          </Grid>
        ) : null}
        {!formData.manufacturerCard ? (
          <FormControl component="fieldset">
            <FormLabel component="legend">
              <Typography
                style={{ fontWeight: 700 }}
                data-testid={
                  TEXT_CONTENT_IDS.PAYMENT_METHOD_SETTINGS_LABEL
                }
              >
                {PAYMENT_METHOD_SETTINGS_TITLE}
              </Typography>
            </FormLabel>
            <Checkbox
              id="isDefault"
              label="Set as default payment method"
              value={formData.isDefault}
              testId={
                TEXT_CONTENT_IDS.ADD_PAYMENT_SET_AS_DEFAULT_CHECKBOX
              }
              onChange={(value) => {
                handleOnChange('isDefault', value);
              }}
            />
          </FormControl>
        ) : null}
      </Grid>
      <FormField
        id="cardNickname"
        label="Card nickname"
        placeholder=""
        value={formData.nickname}
        errorMessage={formErrors.nickname}
        required
        helperText="Card nickname must be 30 characters or less and can only contain letters and numbers."
        hintText="(Optional)"
        autocomplete="name"
        onChange={(value) => {
          handleOnChange('nickname', value);
        }}
        onBlur={(value) => {
          handleOnBlur('nickname', value);
        }}
      />
      {showActionsOnForm ? (
        <Grid
          container
          direction="column"
          sx={{ gap: '16px', marginTop: '24px' }}
          position="sticky"
          display="absolute"
        >
          <Button
            onClick={handleOnSubmit}
            variant="contained"
            color="primary"
          >
            Save
          </Button>
          <Button
            isLoading={false}
            onClick={onCancel}
            variant="contained"
            color="secondary"
          >
            {CANCEL_BUTTON}
          </Button>
        </Grid>
      ) : null}
    </Box>
  );
};

export default SycurioPhoneEntrySubmitView;
