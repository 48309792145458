import { telemetryKeyPrefix } from '../../../services/AppInsights/utils/telemetryKeyPrefix';

export type SycurioMetadataArgs = Partial<
  Record<
    | 'origin'
    | 'clientReference'
    | 'accountId'
    | 'semafoneCR'
    | 'checkoutSessionId'
    | 'merchantId',
    string
  >
>;

export const transformSycurioMetadata = (
  data: SycurioMetadataArgs,
) =>
  Object.entries(data).reduce(
    (sycurioLogProperties, sycurioProperty) => {
      const [key, value] = sycurioProperty;
      return {
        ...sycurioLogProperties,
        [telemetryKeyPrefix(key)]: value,
      };
    },
    {},
  );
