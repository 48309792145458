export const isMessageTypeSubmit = (
  message: string,
): boolean => {
  try {
    const { type } = JSON.parse(message);
    return type === 'submit';
  } catch (error) {
    console.error('Error parsing Sycurio PCI response');
  }
  return false;
};

export const hasCapturedPaymentMethodResponse = (
  message: string,
) => {
  try {
    const keysToCheck = [
      'token',
      'cardHolderName',
      'lastFour',
      'expYear',
      'expMonth',
      'cardType',
    ];
    const { type, data } = JSON.parse(message);

    if (type !== 'submit') {
      return false;
    }

    const missingKeys = keysToCheck.filter(
      (key) => !(key in data),
    );
    return missingKeys.length === 0;
  } catch (error) {
    console.error('Error parsing Sycurio PCI response');
  }
  return false;
};
