import { generateEventMetadataFromSycurioUrl } from './generateEventMetadataFromSycurioUrl';

export type SycurioBaseMetadata = Record<
  'sycurioFragmentUrl' | 'checkoutSessionId' | 'merchantId',
  string
>;

export type SycurioTrackEventArgs = {
  sycurioMetadataArgs: SycurioBaseMetadata;
} & (
  | {
      name: 'insertFragment' | 'cancelFragment';
    }
  | {
      name: 'attachPaymentMethod';
      paymentMethod: {
        paymentMethodId: string;
        customerId: string;
        agentId: string;
      };
    }
);

export const generateTrackEventMetadata = (
  sycurioTrackEvent: SycurioTrackEventArgs,
) => {
  const { name, sycurioMetadataArgs } = sycurioTrackEvent;
  const sycurioMetadata = generateEventMetadataFromSycurioUrl(
    sycurioMetadataArgs,
  );

  return name === 'attachPaymentMethod'
    ? {
        name,
        sycurioMetadata,
        paymentMethod: {
          ...sycurioTrackEvent.paymentMethod,
        },
      }
    : {
        name,
        sycurioMetadata,
      };
};
