export const GUEST = 'guest';
export const BEARER = 'Bearer';
export const AUTHORIZATION = 'Authorization';
export const X_MERCHANT_ID = 'X-Merchant-Id';
export const X_CHECKOUT_ID = 'X-Checkout-Id';
export const X_CUSTOMER_ID = 'X-Customer-Id';
export const X_CCG_WIDGET_VERSION = 'X-Ccg-Widget-Version';
export const X_UPSTREAM_ENV = 'X-Upstream-Env';
export const CHARGE_STATUS = {
  COMPLETED: 'COMPLETED',
  FAILED: 'FAILED',
  VALIDATION_ERROR: 'VALIDATION_ERROR',
} as const;
export type CHARGE_STATUSES = keyof typeof CHARGE_STATUS;
export const SUCCESSFUL_PAYMENT_SUBMISSION_STATUSES = [
  'COMPLETED',
  'AUTHORIZED',
  'ACCEPTED',
];
export const PAYMENT_TRANSACTION_TYPE = {
  SALE: 'SALE',
} as const;
export const HttpStatus = {
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  NETWORK_CONNECT_TIMEOUT_ERROR: 599,
  BAD_GATEWAY: 502,
  SERVICE_UNAVAILABLE: 503,
  GATEWAY_TIMEOUT: 504,
} as const;
export const WALLET_ORIGIN = {
  local: 'http://localhost:3000',
  dev: 'https://dev-wallet.healthsafepay.com',
  test: 'https://tst-wallet.healthsafepay.com',
  reg: 'https://reg-wallet.healthsafepay.com',
  stage: 'https://stg-wallet.healthsafepay.com',
  prod: 'https://wallet.healthsafepay.com',
} as const;
const DEFAULT_DOCUMENT_TITLE = 'Convenient Checkout Gateway';
export const DOCUMENT_TITLE = {
  WALLET_LANDING_PAGE: `${DEFAULT_DOCUMENT_TITLE} - Your Wallet`,
  GUEST_LANDING_PAGE: `${DEFAULT_DOCUMENT_TITLE} - Payment`,
  ADD_PAYMENT: `${DEFAULT_DOCUMENT_TITLE} - Add Payment`,
  REMOVE_PAYMENT: `${DEFAULT_DOCUMENT_TITLE} - Remove Payment`,
  EDIT_PAYMENT: `${DEFAULT_DOCUMENT_TITLE} - Edit Payment`,
};
export const DEFAULT_PAYMENT_DESCRIPTION = 'UI widget payment';
export const DEFAULT_ENV = 'stage';
export const POLL_FOR_CHECKOUT_STATUS_CHECK_INTERVAL = 2000;

export const STATUS_MAPPER = {
  INITIAL: '',
  PENDING: 'Pending',
  NOTIFICATION_CREATED: '{selectedChannel} created',
  NOTIFICATION_SENT: '{selectedChannel} sent',
  LINK_OPENED: 'Link opened',
  NOTIFICATION_FAILED: 'Sending link failed',
  FAILED: 'Failed to add payment method',
  COMPLETED: 'Payment method completed',
  EXPIRED: 'Payment method session expired',
  CANCELED: 'Payment method session canceled',
} as const;

export const INTERNET_CONNECTIVITY_MESSAGES = {
  NO_CONNECTION: 'No internet connection available.',
  CONNECTION_RESTORED: 'Internet connection is available!',
};

/**
 * TODO: This status here isn't really connected to how our backend sends the status, but this is how we've been handling it in the frontend. This is brittle and should be revisited.
 */
export const SESSION_ALREADY_PROCESSED_HTTP_STATUS = 406;
export const SESSSION_ID_INVALID_STATUS_CODE = 9999;
