// Dropdown.tsx
import React from 'react';
import { Box, InputLabel, Select } from '@mui/material';
import { nativeSelectClasses } from '@mui/material/NativeSelect';

import {
  TRANSLATION_CLASS_NAMES,
  ELEMENT_IDS,
} from '../../utils/element-ids';

interface DropdownV2Props {
  value: string | undefined;
  testid?: string;
  disabled?: boolean;
  id?: string;
  label?: string;
  labelId?: string;
}

const DropdownV2: React.FC<DropdownV2Props> = ({
  value,
  testid,
  disabled,
  id,
  label,
  labelId,
}) => {
  return (
    <Box>
      <InputLabel
        id={id}
        data-testid={id}
        sx={{
          fontSize: '.93rem',
          marginBottom: '8px',
          color: '#4B4D4F',
        }}
      >
        {label}
      </InputLabel>
      <Select
        labelId={labelId}
        value={value}
        sx={{
          width: '100%',
          [`& .${nativeSelectClasses.select}`]: {
            backgroundColor: '#F2F2F2',
            fill: '#000000',
            WebkitTextFillColor: '#000000',
          },
          [`& .${nativeSelectClasses.icon}`]: {
            fill: '#000000',
          },
        }}
        MenuProps={{
          PaperProps: {
            sx: {
              backgroundColor: '#F2F2F2',
            },
          },
        }}
        className={
          TRANSLATION_CLASS_NAMES.NO_TRANSLATION_CLASS_NAME
        }
        disabled={disabled}
        variant="outlined"
        data-testid={`${ELEMENT_IDS.LANGUAGE_DROPDOWN_SELECTOR}-${testid}`}
      >
        <option value={value}>{value}</option>
      </Select>
    </Box>
  );
};

export default DropdownV2;
