import type { SycurioPCIResponseType } from './getSycurioPCIResponse';
import { getSycurioPCIResponse } from './getSycurioPCIResponse';

export const hasValidPCIResponse = (
  message: unknown,
): message is SycurioPCIResponseType => {
  if (typeof message !== 'string') {
    return false;
  }

  const sycurioPCIResponse = getSycurioPCIResponse(message);
  return (
    !!sycurioPCIResponse &&
    !!sycurioPCIResponse.token &&
    !!sycurioPCIResponse.lastFour &&
    !!sycurioPCIResponse.expYear &&
    !!sycurioPCIResponse.expMonth &&
    !!sycurioPCIResponse.cardType &&
    !!sycurioPCIResponse.cardHolderName
  );
};
