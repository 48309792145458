import { useEffect } from 'react';

import commonSycurioTelephonicEntryService from '../../../services/commonCheckout/commonSycurioTelephonicEntryService';
import type { SycurioErrorEventType } from '../sycurio-telephonic-entry/SycurioTelephonicEntry';
import { TELEPHONIC_ENTRY_ERROR_MESSAGES } from '../constants/messages';

export default function useStartSycurioTelephonicEntry({
  checkoutSessionId,
  startSpinner,
  onError,
  sycurioErrorMessage = TELEPHONIC_ENTRY_ERROR_MESSAGES.casiError,
}: {
  checkoutSessionId: string;
  startSpinner: () => void;
  onError: (errorEvent: SycurioErrorEventType) => void;
  sycurioErrorMessage: string;
}) {
  useEffect(() => {
    if (!checkoutSessionId) {
      return;
    }
    (async () => {
      startSpinner();
      try {
        await commonSycurioTelephonicEntryService({
          checkoutSessionId,
          eventType: 'startSycurio',
        });
      } catch (e) {
        console.error(
          'Error starting sycurio telephonic entry session',
        );
        onError({
          sycurioErrorType: 'casiError',
          message: sycurioErrorMessage,
        });
      }
    })();
  }, [checkoutSessionId, sycurioErrorMessage]);
}
